/*
Author       : Evoltys Technologies
Template Name: CRMS
Version      : 1.0
*/


/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Animation
6. Toggle Button
7. Modal
8. Header
9. Sidebar
10. Content
11. Login
12. Dashboard
13. Activity
14. Select2
15. Nav tabs
16. Edit Profile
17. Chat
18. Focus Label
19. Employee
20. Events
21. Profile
22. Notifications
23. Task
24. Inbox
25. Mail View
26. Contacts
27. Chat Sidebar
28. Notification Settings
29. Loader
30. Error
31. Components
32. Search
33. FAQ
34. Kanban Board
35. Responsive

========================================*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap');
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(./assets/fonts/MaterialIcons-Regular.eot);
	/* For IE6-8 */
	src: local('Material Icons'), local('MaterialIcons-Regular'), url(./assets/fonts/MaterialIcons-Regular.woff2) format('woff2'), url(./assets/fonts/MaterialIcons-Regular.woff) format('woff'), url(./assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 400;
	src: local('Circular Std Book'), url('/fonts/CircularStd-Book.woff') format('woff');
}

@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 400;
	src: local('Circular Std Book Italic'), url('/fonts/CircularStd-BookItalic.woff') format('woff');
}

@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 500;
	src: local('Circular Std Medium'), url('/fonts/CircularStd-Medium.woff') format('woff');
}

@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 500;
	src: local('Circular Std Medium Italic'), url('/fonts/CircularStd-MediumItalic.woff') format('woff');
}

@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 600;
	src: local('Circular Std Bold'), url('/fonts/CircularStd-Bold.woff') format('woff');
}

@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 600;
	src: local('Circular Std Bold Italic'), url('/fonts/CircularStd-BoldItalic.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 800;
	src: local('Circular Std Black'), url('/fonts/CircularStd-Black.woff') format('woff');
}

@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 800;
	src: local('Circular Std Black Italic'), url('/fonts/CircularStd-BlackItalic.woff') format('woff');
}


/*-----------------
  1. General
-----------------------*/

html {
	height: 100%;
}

body {
	font-family: 'Inter', sans-serif;
	font-size: .9375rem;
	color: #1f1f1f;
	background-color: #f7f7f7;
	min-height: 100%;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter', sans-serif;
	margin-top: 0;
	font-weight: 500;
}

h1,
.h1 {
	font-size: 2.25rem;
}

h2,
.h2 {
	font-size: 1.875rem;
}

h3,
.h3 {
	font-size: 1.5rem;
}

h4,
.h4 {
	font-size: 1.125rem;
}

h5,
.h5 {
	font-size: 0.875rem;
}

h6,
.h6 {
	font-size: 0.75rem;
}

a,
a:hover,
a:active,
a:focus {
	text-decoration: none;
	outline: none;
}

.form-control {
	border-color: #e3e3e3;
	box-shadow: none;
	font-size: 15px;
	height: 44px;
}

.form-control:focus {
	border-color: #ccc;
	box-shadow: none;
	outline: 0 none;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: not-allowed;
}

.form-group {
    margin-bottom: 1.25rem;
}

input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}

input,
input:focus,
button,
button:focus {
	outline: none;
}

input[type="file"] {
	height: auto;
}

input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

textarea.form-control {
	resize: vertical;
}

.navbar-nav>li {
	float: left;
}

.form-control-lg.form-control {
	border-radius: 4px;
	height: 46px;
}

.form-control-sm.form-control {
	height: 30px;
	border-radius: 0;
}

.input-group.input-group-lg .form-control {
	height: 46px;
}

.page-header .dropdown a {
	color: #000;
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}

.input-group-text {
	background-color: #f7f7f7;
	border-color: #e3e3e3;
}

select.form-control[multiple] option,
select.form-control[size] option {
	border-radius: 3px;
	padding: 7px 12px;
}

select.form-control[multiple],
select.form-control[size] {
	height: 200px;
	padding: 7px;
}


/*-----------------
  2. Table
-----------------------*/
.table-responsive{
	position: relative;
	z-index: 0;
}
.table {
	color: #333;
}

.table.table-white {
	background-color: #fff;
}

.table thead th {
	font-weight: 500;
	letter-spacing: 0.05em;
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
	background-color: #f6f6f6;
}

table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}

table.table td h2.table-avatar {
	align-items: center;
	display: inline-flex;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	white-space: nowrap;
}

table.table td h2.table-avatar.blue-link a {
	color: #007bff;
}

table.table td h2 a {
	color: #333;
}

table.table td h2 a:hover {
	color: #0d6efd;
}

table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}

table.dataTable {
	margin-bottom: 15px !important;
	margin-top: 15px !important;
}

.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}

.table-hover tbody tr:hover {
	background-color: #f7f7f7;
	color: #212529;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
	padding-right: 30px !important;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

table.table td,table.table th {
	border-color: #dee2e6;
}

.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}

.dataTables_length select {
    appearance: auto;
}

/*-----------------
  3. Helper Class
-----------------------*/

.p-20 {
	padding: 20px !important;
}

.p-t-0 {
	padding-top: 0 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-r-5 {
	margin-right: 5px !important;
}

.m-r-10 {
	margin-right: 10px !important;
}

.m-l-5 {
	margin-left: 5px !important;
}

.m-l-15 {
	margin-left: 15px !important;
}

.m-t-5 {
	margin-top: 5px !important;
}

.m-t-0 {
	margin-top: 0 !important;
}

.m-t-10 {
	margin-top: 10px !important;
}

.m-t-15 {
	margin-top: 15px !important;
}

.m-t-20 {
	margin-top: 20px !important;
}

.m-t-30 {
	margin-top: 30px !important;
}

.m-t-50 {
	margin-top: 50px !important;
}

.m-b-5 {
	margin-bottom: 5px !important;
}

.m-b-10 {
	margin-bottom: 10px !important;
}

.m-b-15 {
	margin-bottom: 15px !important;
}

.m-b-20 {
	margin-bottom: 20px !important;
}

.m-b-30 {
	margin-bottom: 30px !important;
}

.w-40 {
	width: 40px;
}

.btn-md {
	height: 40px;
}

.block {
	display: block !important;
}

.text-ellipsis {
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-muted-light {
	color: #aaa;
}

.text-xs {
	font-size: .75rem !important;
}

.text-sm {
	font-size: .875rem !important;
}

.text-lg {
	font-size: 1.25rem !important;
}

.text-xl {
	font-size: 1.5rem !important;
}

.font-18 {
	font-size: 18px;
}

.btn.btn-rounded {
	border-radius: 50px;
}


/*-----------------
  4. Bootstrap Classes
-----------------------*/
#add-new-list .btn-light{
	padding: 9px 32px;
    border-radius: 25px;
}
.btn {
	border-radius: 5px;
}

.btn.disabled,
.btn:disabled {
	cursor: not-allowed;
}

.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
}

.btn-white:focus,
.btn-white:hover {
	background-color: #f7f7f7;
}

.rounded,
.circle {
	border-radius: 500px;
}

.bg-purple,
.badge-purple {
	background-color: #7460ee !important;
}

.bg-primary,
.badge-primary {
	background-color: #0d6efd !important;
}

.bg-success,
.badge-success {
	background-color: #55ce63 !important;
}

.bg-info,
.badge-info {
	background-color: #009efb !important;
}

.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}

.bg-danger,
.badge-danger {
	background-color: #f62d51 !important;
}

.bg-white {
	background-color: #fff;
}

.text-primary,
.dropdown-menu>li>a.text-primary {
	color: #0d6efd !important;
}

.text-success,
.dropdown-menu>li>a.text-success {
	color: #55ce63 !important;
}

.text-danger,
.dropdown-menu>li>a.text-danger {
	color: #f62d51 !important;
}

.text-info,
.dropdown-menu>li>a.text-info {
	color: #009efb !important;
}

.text-warning,
.dropdown-menu>li>a.text-warning {
	color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu>li>a.text-purple {
	color: #7460ee !important;
}

.text-muted {
	color: #8e8e8e !important;
}

.btn-purple {
	background-color: #7460ee;
	border: 1px solid #7460ee;
	color: #fff;
}

.btn-purple:hover,
.btn-purple:focus {
	background-color: #482fe9;
	border: 1px solid #482fe9;
}

.btn-primary {
	background-color: #0d6efd;
	border: 1px solid #0d6efd;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
	background-color: #0d6efd;
	border: 1px solid #0d6efd;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
	background-color: #ff851a;
	border: 1px solid #ff851a;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
	background-color: #ff851a;
	border-color: #ff851a;
	color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #0d6efd;
	border-color: #0d6efd;
	color: #fff;
}

.btn-success {
	background-color: #55ce63;
	border: 1px solid #55ce63
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
	background: #4ab657;
	border: 1px solid #4ab657;
	color: #fff;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
	background: #4ab657;
	border: 1px solid #4ab657
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
	background-color: #55ce63;
	border-color: #55ce63;
	color: #fff;
}

.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
	background-color: #009efb;
	border-color: #009efb;
	color: #fff;
}

.btn-warning {
	background: #ffbc34;
	border: 1px solid #ffbc34
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
	background: #e9ab2e;
	border: 1px solid #e9ab2e
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
	background: #e9ab2e;
	border: 1px solid #e9ab2e
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
	background-color: #ffbc34;
	border-color: #ffbc34;
	color: #fff;
}

.btn-danger {
	background: #f62d51;
	border: 1px solid #f62d51
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
	background-color: #e6294b;
	border: 1px solid #e6294b
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
	background: #e9ab2e;
	border: 1px solid #e9ab2e
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #f62d51;
	border-color: #f62d51;
	color: #fff;
}

.btn-custom {
	background: #0d6efd;
	background: -moz-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: -webkit-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: -ms-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: linear-gradient(to right, #0d6efd 0%, #fc6075 100%);
	color: #fff;
}

.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
	background: #0d6efd;
	background: -moz-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: -webkit-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: -ms-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: linear-gradient(to right, #0d6efd 0%, #fc6075 100%);
	color: #fff;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background-color: #0d6efd;
	border-color: #0d6efd;
}

.pagination>li>a,
.pagination>li>span {
	color: #0d6efd;
}

.page-link:hover {
	color: #0d6efd;
}

.page-link:focus {
	box-shadow: unset;
}

.page-item.active .page-link {
	background-color: #0d6efd;
	border-color: #0d6efd;
}

.dropdown-menu {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	transform-origin: left top 0;
	box-shadow: inherit;
	background-color: #fff;
}

.navbar-nav .open .dropdown-menu {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
	font-size: 13px;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	box-shadow: none;
}

.btn-link {
	color: #333;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.form-text {
	color: #999;
	font-size: 12px;
	margin-bottom: 7px;
	margin-top: 7px;
}

.btn.focus,
.btn:focus {
	box-shadow: unset;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: #0d6efd;
}

.list-group-item {
	border: 1px solid #e3e3e3;
}

.custom-file {
	height: 44px;
}

.custom-file .custom-file-label {
	border: 1px solid #e3e3e3;
	height: 44px;
	margin-bottom: 0;
	padding: 10px 15px;
}

.custom-file-label::after {
	background-color: #f2f2f2;
	height: 42px;
	padding: 10px 15px;
}

.custom-file input[type="file"] {
	height: 44px;
}

.bg-inverse-primary {
	background: rgba(77, 138, 240, .2) !important;
	color: #4d8af0 !important;
}

.bg-inverse-secondary {
	background: rgba(194, 198, 209, .2) !important;
	color: #c2c6d1 !important;
}

.bg-inverse-success {
	background-color: rgba(15, 183, 107, 0.12) !important;
	color: #26af48 !important;
}

.bg-inverse-purple {
	background: rgba(108, 97, 246, .2) !important;
	color: #6c61f6 !important;
}

.bg-inverse-warning {
	background-color: rgba(255, 152, 0, 0.12) !important;
	color: #f39c12 !important;
}

.bg-inverse-danger {
	background-color: rgba(242, 17, 54, 0.12) !important;
	color: #e63c3c !important;
}

.bg-inverse-light {
	background: rgba(223, 223, 223, .2) !important;
	color: #dfdfdf !important;
}

.bg-inverse-dark {
	background: rgba(116, 118, 123, .2) !important;
	color: #74767b !important;
}

.bg-inverse-white {
	background: rgba(255, 255, 255, .2) !important;
	color: #fff !important;
}

.bg-inverse-info {
	background-color: rgba(2, 182, 179, 0.12) !important;
	color: #1db9aa !important;
}

.bg-inverse-default {
	background-color: rgba(40, 52, 71, 0.12) !important;
	color: #283447 !important;
}

.bg-gradient-primary {
	background: linear-gradient(to right, #0dcaf0, #0d6efd);
}

.bg-gradient-secondary {
	background: linear-gradient(to bottom right, #f35c38 0, #f35c38 100%);
}

.bg-gradient-success {
	background: linear-gradient(to bottom right, #0ba408 0, #28e224 100%);
}

.bg-gradient-info {
	background: linear-gradient(to bottom right, #69c7de 0, #1e9fc4 100%);
}

.bg-gradient-warning {
	background: linear-gradient(to bottom right, #ecd53e 0, #efaf28 100%)
}

.bg-gradient-teal {
	background: linear-gradient(to bottom right, #00796b 0, #4db6ac 100%);
}

.bg-gradient-blue {
	background: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}

.bg-gradient-danger {
	background: linear-gradient(to right, #ffbf96, #fe7096);
}

.bg-gradient-purple {
	background: linear-gradient(to right, #8a56e6 0, #6f3cca 100%);
}


/*-----------------
  5. Animation
-----------------------*/

@-moz-keyframes pulse {
	0% {
		-moz-transform: scale(0);
		opacity: 0.0;
	}
	25% {
		-moz-transform: scale(0);
		opacity: 0.1;
	}
	50% {
		-moz-transform: scale(0.1);
		opacity: 0.3;
	}
	75% {
		-moz-transform: scale(0.5);
		opacity: 0.5;
	}
	100% {
		-moz-transform: scale(1);
		opacity: 0.0;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;
	}
	25% {
		-webkit-transform: scale(0);
		opacity: 0.1;
	}
	50% {
		-webkit-transform: scale(0.1);
		opacity: 0.3;
	}
	75% {
		-webkit-transform: scale(0.5);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 0.0;
	}
}

@-webkit-keyframes taskHighlighter {
	0% {
		background: #FEEB99;
	}
	100% {
		background: #fff;
	}
}

@keyframes taskHighlighter {
	0% {
		background: #FEEB99;
	}
	100% {
		background: #fff;
	}
}


/*-----------------
  6. Toggle Button
-----------------------*/

.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}

.checktoggle {
	background-color: #e0001a;
	border-radius: 12px;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 24px;
	margin-bottom: 0;
	position: relative;
	width: 48px;
}

.checktoggle:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(5px, -50%);
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 50%;
	transition: left 300ms ease, transform 300ms ease;
}

.check:checked+.checktoggle {
	background-color: #55ce63;
}

.check:checked+.checktoggle:after {
	left: 100%;
	transform: translate(calc(-100% - 5px), -50%);
}

.onoffswitch {
	margin-left: auto;
	position: relative;
	width: 73px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.onoffswitch-checkbox {
	display: none;
}

.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}

.onoffswitch-inner {
	display: block;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
	width: 200%;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
	box-sizing: border-box;
	color: #fff;
	display: block;
	float: left;
	font-size: 16px;
	height: 30px;
	line-height: 32px;
	padding: 0;
	width: 50%;
}

.onoffswitch-inner:before {
	background-color: #55ce63;
	color: #fff;
	content: "ON";
	padding-left: 14px;
}

.onoffswitch-inner:after {
	content: "OFF";
	padding-right: 14px;
	background-color: #ccc;
	color: #fff;
	text-align: right;
}

.onoffswitch-switch {
	background: #fff;
	border-radius: 20px;
	bottom: 0;
	display: block;
	height: 20px;
	margin: 5px;
	position: absolute;
	right: 43px;
	top: 0;
	transition: all 0.3s ease-in 0s;
	width: 20px;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
	right: 0px;
}


/*-----------------
  7. Modal
-----------------------*/

.modal {
	-webkit-overflow-scrolling: touch;
}

.modal-footer.text-center {
	justify-content: center;
}

.modal-footer.text-start {
	justify-content: flex-start;
}

.modal-dialog.modal-md {
	max-width: 600px;
}

.custom-modal .modal-content {
	border: 0;
	border-radius: 10px;
}

.custom-modal .modal-footer {
	border: 0;
}

.custom-modal .modal-header {
	border: 0;
	justify-content: center;
	padding: 30px 30px 0;
}

.custom-modal .modal-footer {
	border: 0;
	justify-content: center;
	padding: 0 30px 30px;
}

.custom-modal .modal-body {
	padding: 30px;
}

.custom-modal .close {
	background-color: #a0a0a0;
	border-radius: 50%;
	color: #fff;
	font-size: 13px;
	height: 20px;
	line-height: 20px;
	margin: 0;
	opacity: 1;
	padding: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 20px;
	z-index: 99;
}

.close {
    border: 0;
    background: transparent;
    font-size: 20px;
    line-height: normal;
}

.custom-modal .modal-title {
	font-size: 22px;
}

.modal-backdrop.show {
	opacity: 0.4;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
}

.modal .card {
	box-shadow: unset;
}


/*-----------------
  8. Header
-----------------------*/

.header {
	background: #fff;
	height: 60px;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1002;
}

.header .header-left {
	float: left;
	height: 60px;
	padding: 0 20px;
	position: relative;
	text-align: center;
	width: 230px;
	z-index: 1;
	transition: all 0.2s ease-in-out;
}

.mini-sidebar-logo {
	display: none;
}

.mini-sidebar .header-left .mini-sidebar-logo {
	display: block;
}

.mini-sidebar .header-left .sidebar-logo {
	display: none;
}

.header-left img {
	width: 70%;
}

.header .header-left .logo {
	display: inline-block;
	line-height: 55px;
}

.bar-icon {
	display: inline-block;
	width: 21px;
}

.bar-icon span {
	background-color: #999;
	border-radius: 500px;
	display: block;
	float: left;
	height: 2px;
	margin-bottom: 5px;
	width: 21px;
}

.bar-icon span:nth-child(2) {
	width: 15px;
}

.bar-icon span:nth-child(3) {
	margin-bottom: 0;
}

.header .navbar-nav .badge {
	position: absolute;
	right: 7px;
	top: 4px;
}

.header .dropdown-menu>li>a {
	position: relative;
}

.header .dropdown-toggle::after {
	display: none;
}

.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #999;
	border-right: 2px solid #999;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.page-title-box {
	border-radius: 0;
	float: left;
	height: 60px;
	margin-bottom: 0;
	padding: 0px 20px;
}

.page-title-box h3 {
	color: #333;
	font-size: 20px;
	font-weight: normal;
	margin: 0;
}

.user-menu {
	float: right;
	margin: 0;
	position: relative;
	z-index: 99;
}

.user-menu.nav>li>a {
	color: #333;
	font-size: 15px;
	line-height: 60px;
	padding: 0 15px;
	height: 60px;
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}

.flag-nav img {
	margin-right: 5px;
}

.flag-nav .dropdown-menu {
	max-height: 150px;
	min-width: 120px;
	overflow-y: auto;
}

.flag-nav .dropdown-menu .dropdown-item {
	padding: 5px 15px;
}

.user-img {
	display: inline-block;
	position: relative;
	margin-right: 5px;
}

.main-drop .user-img img {
	border-radius: 50%;
	width: 30px;
}

.user-img .status {
	border: 2px solid #fff;
	bottom: 0;
	height: 10px;
	margin: 0;
	position: absolute;
	right: 0;
	width: 10px;
}

.user-menu .user-img .status {
	bottom: 12px;
}

.status {
	background-color: #263238;
	border-radius: 50%;
	display: inline-block;
	height: 10px;
	width: 10px;
}

.status.online {
	background-color: #55ce63;
}

.status.offline {
	background-color: #f62d51;
}

.status.away {
	background-color: #faa937;
}

.main-drop .dropdown-menu {
	min-width: 130px;
	padding: 0;
}

.crms-title .col {
	padding: 0;
}


/*-----------------
  9. Sidebar
-----------------------*/

.sidebar {
	background-color: #fff;
	box-shadow: 0px 0px 20px 2px #eaeaea;
	bottom: 0;
	left: 0;
	margin-top: 0;
	position: fixed;
	top: 60px;
	transition: all 0.2s ease-in-out 0s;
	width: 230px;
	z-index: 1001;
}

.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.sidebar-inner {
	height: 100%;
	transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu {
	padding: 0;
}

.sidebar-menu ul {
	font-size: 15px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.sidebar-menu li a {
	color: #6f6b6b;
	display: block;
	font-size: 15px;
	height: auto;
	padding: 0 20px;
}

.sidebar-menu li a:hover {
	background-color: #0d6efd;
	color: #fff;
}

.sidebar-menu li.active a {
	color: #fff;
	background-color: #0d6efd;
}

.menu-title {
	color: #9e9e9e;
	display: flex;
	font-size: 14px;
	opacity: 1;
	padding: 5px 15px;
	white-space: nowrap;
}

.menu-title>i {
	float: right;
	line-height: 40px;
}

.sidebar-menu li.menu-title a {
	color: #0d6efd;
	display: inline-block;
	margin-left: auto;
	padding: 0;
}

.sidebar-menu li.menu-title a.btn {
	color: #fff;
	display: block;
	float: none;
	font-size: 15px;
	margin-bottom: 15px;
	padding: 10px 15px;
}

.sidebar-menu ul ul a.active {
	background-color: #0d6efd;
	color: #fff;
	text-decoration: underline;
}

.mobile-user-menu {
	color: #fff;
	display: none;
	float: right;
	font-size: 24px;
	height: 60px;
	line-height: 60px;
	padding: 0 20px;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
	width: 60px;
	z-index: 10;
}

.mobile-user-menu>a {
	color: #333;
	padding: 0;
}

.mobile-user-menu a:hover {
	color: #333;
}

.mobile-user-menu>.dropdown-menu {
	min-width: 130px;
}

.mobile-user-menu>.dropdown-menu>a {
	line-height: 23px;
}

.profile-rightbar {
	display: none !important;
	color: #bbb;
	font-size: 26px;
	margin-left: 15px;
}

.mobile_btn {
	display: none;
	float: left;
}

.sidebar .sidebar-menu>ul>li>a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 15px;
	white-space: nowrap;
}

.sidebar .sidebar-menu>ul>.nav-profile>a span {
	margin-left: 8px;
}

.sidebar .sidebar-menu>ul>li>a span.chat-user {
	margin-left: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
	margin-left: auto;
}

.sidebar-menu ul ul a {
	display: block;
	font-size: 14px;
	padding: 9px 10px 9px 50px;
	position: relative;
}

.sidebar-menu ul ul {
	display: none;
}

.sidebar-menu ul ul ul a {
	padding-left: 70px;
}

.sidebar-menu ul ul ul ul a {
	padding-left: 90px;
}

.sidebar-menu>ul>li {
	position: relative;
}

.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 15px;
	display: inline-block;
	font-family: 'FontAwesome';
	text-rendering: auto;
	line-height: 40px;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 11px;
}

.sidebar-menu .menu-arrow:before {
	content: "\f105";
}

.sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.noti-dot:before {
	content: '';
	width: 5px;
	height: 5px;
	border: 5px solid #0d6efd;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background-color: #0d6efd;
	z-index: 10;
	position: absolute;
	right: 37px;
	top: 15px;
}

.noti-dot:after {
	content: '';
	border: 4px solid #0d6efd;
	background: transparent;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	height: 24px;
	width: 24px;
	-webkit-animation: pulse 3s ease-out;
	-moz-animation: pulse 3s ease-out;
	animation: pulse 3s ease-out;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	position: absolute;
	top: 8px;
	right: 30px;
	z-index: 1;
	opacity: 0;
}

.sidebar-menu ul ul a .menu-arrow {
	top: 6px;
}

.sidebar-menu a {
	line-height: 1.3;
	transition: unset;
	-moz-transition: unset;
	-o-transition: unset;
	-ms-transition: unset;
	-webkit-transition: unset;
}

.sidebar-menu>ul>li>a {
	padding: 8px 15px;
	align-items: center;
	display: flex;
	justify-content: flex-start;
	position: relative;
}

.sidebar-menu li .active {
	background: #0d6efd;
	color: #fff;
}

.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 20px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 20px;
	transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li.menu-title a i {
	font-size: 16px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}

.sidebar-menu li a>.badge {
	color: #fff;
}


/*-----------------
  10. Content
-----------------------*/

.page-wrapper {
	left: 0;
	margin-left: 230px;
	padding-top: 60px;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.page-wrapper>.content {
	padding: 30px;
}

.page-header {
	margin-bottom: 1.875rem;
}

.page-header .breadcrumb {
	background-color: transparent;
	color: #6c757d;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 0;
	padding: 0;
}

.page-header .breadcrumb a {
	color: #333;
}

.card {
	border: 1px solid #ededed;
	box-shadow: 0px 0px 20px 2px #eaeaea;
	margin-bottom: 30px;
}

.card-title {
	color: #1f1f1f;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 20px;
}

.card-header {
	background-color: #fff;
}

.card-footer {
	background-color: #fff;
}

.page-title {
	color: #1f1f1f;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0;
}

.breadcrumb {
	padding: 0;
}

.page-sub-title {
	color: #565656;
	font-size: 18px;
	font-weight: normal;
	margin-bottom: 20px;
}

.add-btn {
	background-color: #0d6efd;
	border: 1px solid #0d6efd;
	border-radius: 50px;
	color: #fff;
	float: right;
	font-weight: 500;
	min-width: 140px;
}

.add-btn:hover,
.add-btn:focus,
.add-btn:active {
	background-color: #ff851a;
	border: 1px solid #ff851a;
	color: #fff;
}

.add-btn i {
	margin-right: 5px;
}

.cal-icon {
	position: relative;
	width: 100%;
}

.cal-icon:after {
	color: #979797;
	content: "\f073";
	display: block;
	font-family: "FontAwesome";
	font-size: 15px;
	margin: auto;
	position: absolute;
	right: 15px;
	top: 10px;
}

.form-focus .cal-icon:after {
	top: 15px;
}


/*-----------------
  11. Login
-----------------------*/

.account-page {
	align-items: center;
	display: flex;
}

.account-page .main-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.account-content {
	padding: 20px 0;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
}

.account-title {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 5px;
	text-align: center;
}

.account-subtitle {
	color: #4c4c4c;
	font-size: 18px;
	margin-bottom: 30px;
	text-align: center;
}

.account-box {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	margin: 0 auto;
	overflow: hidden;
	width: 480px;
}

.account-wrapper {
	padding: 30px;
}

.account-logo {
	margin-bottom: 30px;
	text-align: center;
}

.logo-login {
	margin-bottom: 30px;
	text-align: center;
}


.account-box .form-group {
	margin-bottom: 25px;
}

.account-box .account-btn {
	background: #0d6efd;
	background: -moz-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: -webkit-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: -ms-linear-gradient(left, #0d6efd 0%, #fc6075 100%);
	background: linear-gradient(to right, #0d6efd 0%, #fc6075 100%);
	border: 0;
	border-radius: 4px;
	display: block;
	font-size: 22px;
	padding: 10px 26px;
	width: 100%;
}

.account-box .account-btn:hover,
.account-box .account-btn:focus {
	border: 0;
	opacity: 0.8;
}

.account-box .form-control {
	background-color: #fbfbfb;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	height: 46px;
}

.account-box label {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 5px;
}

.account-logo img {
	width: 100px;
}

.logo-login img {
	width: 200px;
}


.account-footer {
	text-align: center;
}

.account-footer p {
	margin-bottom: 0;
}

.account-footer a {
	color: #0d6efd;
}

.account-footer a:hover {
	color: #0d6efd;
}


/*-----------------
  12. Dashboard
-----------------------*/

.card-table .card-header {
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	padding: 15px;
}

.card-table .card-body {
	padding: 0;
}

.card-table .card-footer {
	background-color: #fff;
	text-align: center;
}

.card-table .card-footer a {
	color: #333;
}

.card-title>a.btn {
	color: #fff;
}

.custom-table tr {
	background-color: #fff;
	box-shadow: 0 0 3px #e5e5e5;
}

.table.custom-table>tbody>tr>td,
.table.custom-table>tbody>tr>th,
.table.custom-table>tfoot>tr>td,
.table.custom-table>tfoot>tr>th,
.table.custom-table>thead>tr>td,
.table.custom-table>thead>tr>th {
	padding: 10px 8px;
	vertical-align: middle;
}

.table.custom-table>tbody>tr>td:first-child,
.table.custom-table>thead>tr>th:first-child {
	padding-left: 15px;
}

.table.custom-table>tbody>tr>td:last-child,
.table.custom-table>thead>tr>th:last-child {
	padding-right: 15px;
}

.dash-widget-icon {
	background-color: rgba(255, 155, 68, 0.2);
	border-radius: 100%;
	color: #0d6efd;
	display: inline-block;
	float: left;
	font-size: 30px;
	height: 60px;
	line-height: 60px;
	margin-right: 10px;
	text-align: center;
	width: 60px;
}

.dash-widget-info {
	text-align: right;
}

.dash-widget-info>h3 {
	font-size: 30px;
	font-weight: 600;
}

.dash-widget-info>span {
	font-size: 16px;
}

.dash-statistics .stats-info {
	margin-bottom: 5px;
}

.dash-statistics .stats-info:last-child {
	margin-bottom: 0;
}

.dash-statistics .progress {
	height: 4px;
}

.dash-statistics .stats-list {
	height: auto;
}

.leave-info-box {
	border: 1px solid #e5e5e5;
	padding: 15px;
	margin-bottom: 15px;
}

.leave-info-box:last-child {
	margin-bottom: 0;
}

.load-more a {
	background-color: #fff;
	border: 1px solid #eaeaea;
	border-radius: 3px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	display: inline-block;
	font-size: 14px;
	padding: 5px 15px;
}


/*-----------------
  13. Activity
-----------------------*/

.activity-box {
	position: relative;
}

.activity-list {
	list-style: none;
	margin: 0 0 0 10px;
	padding: 0;
	position: relative;
}

.activity .activity-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.activity .activity-list>li .activity-user {
	height: 32px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 12px;
	left: 8px;
	width: 32px;
}

.activity .activity-list>li .activity-content {
	background-color: #fff;
	margin: 0 0 0 40px;
	padding: 0;
	position: relative;
}

.activity-list::before {
	background: #ddd;
	bottom: 0;
	content: "";
	left: 23px;
	position: absolute;
	top: 8px;
	width: 2px;
}

.activity .activity-list li::before {
	background: #ddd;
	bottom: 0;
	content: "";
	left: 22px;
	position: absolute;
	top: 12px;
	width: 2px;
}

.activity-list li::before {
	background: #eee;
	bottom: 0;
	content: "";
	left: 8px;
	position: absolute;
	top: 8px;
	width: 2px;
}

.activity-list>li {
	background-color: #fff;
	margin-bottom: 10px;
	padding: 10px;
	position: relative;
	border: 1px solid #ededed;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.activity-list>li:last-child .activity-content {
	margin-bottom: 0;
}

.activity-user .avatar {
	height: 32px;
	line-height: 32px;
	margin: 0;
	width: 32px;
}

.activity-list>li .activity-user {
	background: #fff;
	height: 32px;
	left: -7px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 3px;
	width: 32px;
}

.activity-list>li .activity-content {
	background-color: #fff;
	margin: 0 0 20px 40px;
	padding: 0;
	position: relative;
}

.activity-list>li .activity-content .timeline-content {
	color: #9e9e9e;
}

.activity-list>li .activity-content .timeline-content a {
	color: #616161;
}

.activity-list>li .time {
	color: #bdbdbd;
	display: block;
	font-size: 13px;
}


/*-----------------
  14. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	border-radius: 0.25rem;
	height: 44px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 42px;
	right: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 15px;
	font-weight: normal;
	line-height: 42px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #0d6efd;
}


/*-----------------
  15. Nav Tabs
-----------------------*/

.nav-tabs .nav-link {
	border-radius: 0;
}

.nav-tabs>li>a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	background-color: #eee;
	border-color: transparent;
	color: #333;
}

.nav-tabs.nav-justified>li>a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs.nav-justified>li>a:hover,
.nav-tabs.nav-justified>li>a:focus {
	border-bottom-color: #ddd;
}

.nav-tabs.nav-justified.nav-tabs-solid>li>a {
	border-color: transparent;
}

.nav-tabs.nav-tabs-solid>li>a {
	color: #333;
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
	background-color: #0d6efd;
	border-color: #0d6efd;
	color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a {
	border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:focus {
	border-radius: 50px;
}

.nav-tabs-justified>li>a {
	border-radius: 0;
	margin-bottom: 0;
}

.nav-tabs-justified>li>a:hover,
.nav-tabs-justified>li>a:focus {
	border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid>li>a {
	border-color: transparent;
}

.tab-content {
	padding-top: 15px;
}


/*-----------------
  16. Holidays
-----------------------*/

.custom-table tr.holiday-completed {
	color: #aaa;
}

.dropdown.action-label {
	display: inline-block;
}

.action-label>a {
	display: inline-block;
	min-width: 103px;
}

.action-label .btn-sm {
	padding: 4px;
}


/*-----------------
  17. Delete Modal
-----------------------*/

.form-header {
	text-align: center;
	margin-bottom: 30px;
}

.form-header h3 {
	color: #333;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 10px;
}

.form-header p {
	color: #929292;
	font-size: 15px;
	margin-bottom: 0;
}

.modal-btn>.row {
	margin-left: -8px;
	margin-right: -8px;
}

.modal-btn>.row>div {
	padding-left: 8px;
	padding-right: 8px;
}

.continue-btn {
	background-color: #fff;
	border: 1px solid #0d6efd;
	border-radius: 50px;
	color: #0d6efd;
	display: block;
	font-size: 18px;
	font-weight: 600;
	padding: 10px 20px;
	text-align: center;
}

.continue-btn:hover,
.continue-btn:focus,
.continue-btn:active {
	background-color: #0d6efd;
	border: 1px solid #0d6efd;
	color: #fff;
}

.cancel-btn {
	background-color: #fff;
	border: 1px solid #0d6efd;
	border-radius: 50px;
	color: #0d6efd;
	display: block;
	font-size: 18px;
	font-weight: 600;
	padding: 10px 20px;
	text-align: center;
}

.cancel-btn:hover,
.cancel-btn:focus,
.cancel-btn:active {
	background-color: #0d6efd;
	border: 1px solid #0d6efd;
	color: #fff;
}


/*-----------------
  18. Edit Profile
-----------------------*/

.profile-img-wrap {
	height: 120px;
	position: absolute;
	width: 120px;
	background: #fff;
	overflow: hidden;
}

.profile-basic {
	margin-left: 140px;
}

.profile-basic .cal-icon {
	width: 100%;
}

.profile-img-wrap img {
	border-radius: 50%;
	height: 120px;
	width: 120px;
}

.fileupload.btn {
	position: absolute;
	right: 0;
	bottom: 0;
	background: rgba(33, 33, 33, 0.5);
	border-radius: 0;
	padding: 3px 10px;
	border: none;
}

.fileupload input.upload {
	cursor: pointer;
	filter: alpha(opacity=0);
	font-size: 20px;
	margin: 0;
	opacity: 0;
	padding: 0;
	position: absolute;
	right: -3px;
	top: -3px;
	padding: 5px;
}

.btn-text {
	color: #fff;
}

.submit-section {
	text-align: center;
	margin-top: 40px;
}

.submit-btn {
	border-radius: 50px;
	font-size: 18px;
	font-weight: 600;
	min-width: 200px;
	padding: 10px 20px;
}

.form-scroll {
	max-height: 400px;
	overflow-y: auto;
}

.profile-img-wrap.edit-img {
	border-radius: 50%;
	margin: 0 auto 30px;
	position: relative;
}

.profile-img-wrap.edit-img .fileupload.btn {
	left: 0;
}


/*-----------------
  19. Chat
-----------------------*/

.chat-avatar-sm {
	display: inline-block;
	float: left;
	margin-left: 0 !important;
	margin-right: 10px;
	position: relative;
	width: 24px;
}

.chat-avatar-sm img {
	width: 24px;
}

.chat-avatar-sm.user-img .status {
	bottom: 0;
	right: -3px;
}

.chat-main-row {
	bottom: 0;
	left: 0;
	overflow: auto;
	padding-bottom: inherit;
	padding-top: inherit;
	position: absolute;
	right: 0;
	top: 0;
}

.chat-main-wrapper {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
}

.message-view {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	width: 75%;
}

.chat-window {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
	background-color: #f7f7f7;
}

.fixed-header {
	background-color: #fff;
	border-bottom: 1px solid #eaeaea;
	padding: 10px 15px;
}

.fixed-header .navbar {
	border: 0 none;
	margin: 0;
	min-height: auto;
	padding: 0;
}

.fixed-header .user-info a {
	color: #555;
	text-transform: uppercase;
}

.typing-text {
	color: #0d6efd;
	font-size: 12px;
	text-transform: lowercase;
}

.last-seen {
	color: #888;
	display: block;
	font-size: 12px;
}

.custom-menu {
	margin-top: 6px;
}

.fixed-header .custom-menu {
	margin: 0 0 1px;
}

.custom-menu.nav>li>a {
	color: #bbb;
	font-size: 26px;
	line-height: 32px;
	margin-left: 15px;
	padding: 0;
}

.custom-menu.navbar-nav>li>a:hover,
.custom-menu.navbar-nav>li>a:focus {
	background-color: transparent;
}

.custom-menu .dropdown-menu {
	left: auto;
	right: 0;
}

.search-box {
	margin-top: 5px;
}

.search-box .input-group {
	background-color: #f7f7f7;
	border: 1px solid #e3e3e3;
	border-radius: 50px;
}

.search-box .input-group .form-control {
	background: transparent;
	border: none;
}

.search-box .btn {
	background-color: transparent;
}

.search-input {
	border-radius: 5px;
}

.chat-contents {
	display: table-row;
	height: 100%;
}

.chat-content-wrap {
	height: 100%;
	position: relative;
	width: 100%;
}

.chat-wrap-inner {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.chats {
	padding: 30px 15px;
}

.chat-body {
	display: block;
	margin: 10px 0 0;
	overflow: hidden;
}

.chat-body:first-child {
	margin-top: 0
}

.chat-right .chat-content {
	background-color: #f2f2f2;
	border: 1px solid #e3e3e3;
	border-radius: 20px 2px 2px 20px;
	clear: both;
	color: #727272;
	display: block;
	float: right;
	margin: 0;
	max-width: 60%;
	padding: 8px 15px;
	position: relative;
}

.chat-right .chat-body {
	padding-left: 48px;
	padding-right: 10px;
}

.chat-bubble {
	display: block;
	width: 100%;
	float: left;
	margin-bottom: 10px;
}

.chat-bubble:hover .chat-action-btns {
	display: block;
	float: left;
}

.chat-right .chat-bubble:hover .chat-action-btns {
	float: right;
	display: block;
}

.chat.chat-right .chat-bubble:last-child .chat-content {
	border-bottom-right-radius: 20px;
}

.chat.chat-right .chat-bubble:first-child .chat-content {
	border-top-right-radius: 20px;
}

.chat-content>p {
	margin-bottom: 0;
}

.chat-time {
	color: rgba(0, 0, 0, 0.5);
	display: block;
	font-size: 12px;
}

.chat-right .chat-time {
	text-align: right;
}

.chat-bubble .chat-action-btns {
	display: none;
}

.chat-action-btns {
	float: right;
}

.chat-action-btns ul {
	list-style: none;
	padding: 0;
	margin: 15px 15px 0 15px;
}

.chat-action-btns ul>li {
	display: inline-block;
	margin-left: 5px;
	font-size: 18px;
}

.chat-right .chat-action-btns {
	float: left;
}

.chat-bubble .chat-action-btns a {
	color: #8c8c8c;
}

.chat-line {
	border-bottom: 1px solid #eaeaea;
	height: 12px;
	margin: 7px 0 20px;
	position: relative;
	text-align: center;
}

.chat-date {
	background-color: #f7f7f7;
	color: #727272;
	font-size: 12px;
	padding: 0 11px;
}

.chat-avatar {
	float: right;
}

.chat-avatar .avatar {
	line-height: 30px;
	height: 30px;
	width: 30px;
}

.chat-left .chat-avatar {
	float: left
}

.chat-left .chat-body {
	margin-right: 0;
	margin-left: 30px;
	padding-right: 20px;
}

.chat-left .chat-content {
	background-color: #fff;
	border: 1px solid #eaeaea;
	color: #888;
	float: left;
	position: relative;
	padding: 8px 15px;
	border-radius: 2px 20px 20px 2px;
	max-width: 60%;
}

.avatar {
	background-color: #aaa;
	border-radius: 50%;
	color: #fff;
	display: inline-block;
	font-weight: 500;
	height: 38px;
	line-height: 38px;
	margin: 0 10px 0 0;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	width: 38px;
	position: relative;
	white-space: nowrap;
}

.avatar:hover {
	color: #fff;
}

.avatar.avatar-xs {
	width: 24px;
	height: 24px
}

.avatar>img {
	border-radius: 50%;
	display: block;
	overflow: hidden;
	width: 100%;
}

.chat.chat-left .chat-bubble:first-child .chat-content {
	border-top-left-radius: 20px;
	margin-top: 0;
}

.chat.chat-left .chat-bubble:last-child .chat-content {
	border-bottom-left-radius: 20px;
}

.chat-left .chat-time {
	color: #a9a9a9
}

.attach-list {
	color: #a3a3a3;
	padding: 5px 0 0;
	line-height: 24px;
	margin: 0;
	list-style: none;
}

.attach-list i {
	margin-right: 3px;
	font-size: 16px;
}

.chat-footer {
	background-color: #fff;
	border-top: 1px solid #eaeaea;
	padding: 15px;
}

.message-bar {
	display: table;
	height: 44px;
	position: relative;
	width: 100%;
}

.message-bar .message-inner {
	display: table-row;
	height: 100%;
	padding: 0 8px;
	width: 100%;
}

.message-bar .link {
	color: #777;
	display: table-cell;
	font-size: 20px;
	padding: 0 10px;
	position: relative;
	vertical-align: middle;
	width: 30px;
}

.message-bar .message-area {
	display: table-cell;
}

.message-area .input-group .form-control {
	background-color: #fff;
	border: 1px solid #eaeaea;
	box-shadow: none;
	color: #555;
	display: block;
	font-size: 14px;
	height: 44px;
	margin: 0;
	padding: 6px 12px;
	resize: none;
}

.message-area .btn {
	height: 44px;
	width: 50px;
}

.profile-right {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
}

.display-table {
	display: table;
	table-layout: fixed;
	border-spacing: 0;
	width: 100%;
	height: 100%;
}

.table-row {
	display: table-row;
	height: 100%;
}

.table-body {
	position: relative;
	height: 100%;
	width: 100%;
}

.table-content {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.chat-profile-img {
	padding: 30px;
	position: relative;
	text-align: center;
}

.edit-profile-img {
	height: 120px;
	margin: 0 auto;
	position: relative;
	width: 120px;
	cursor: pointer;
}

.edit-profile-img img {
	border-radius: 50%;
	height: auto;
	margin: 0;
	width: 120px;
}

.change-img {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	color: #fff;
	display: none;
	height: 100%;
	left: 0;
	line-height: 120px;
	position: absolute;
	top: 0;
	width: 100%;
}

.edit-profile-img:hover .change-img {
	display: block;
}

.edit-profile-img img {
	height: auto;
	margin: 0;
	width: 120px;
	border-radius: 50%;
}

.user-name {
	color: #333;
}

.edit-btn {
	border-radius: 40px;
	height: 36px;
	position: absolute;
	right: 15px;
	top: 15px;
	width: 36px;
}

.chat-profile-info {
	padding: 15px;
}

.user-det-list {
	list-style: none;
	padding: 0;
}

.user-det-list>li {
	padding: 6px 15px;
}

.user-det-list .text-muted {
	color: #888;
}

.transfer-files .tab-content {
	padding-top: 0;
}

.files-list {
	list-style: none;
	padding-left: 0;
}

.files-list>li {
	border-bottom: 1px solid #eaeaea;
	border-radius: inherit;
	margin: 2px 0 0;
	padding: 10px;
}

.files-cont {
	position: relative;
}

.file-type {
	height: 48px;
	position: absolute;
	width: 48px;
}

.files-icon {
	background-color: #f3f7f9;
	border: 1px solid #e4eaec;
	border-radius: 4px;
	display: inline-block;
	height: 38px;
	line-height: 38px;
	text-align: center;
	width: 38px;
}

.files-icon i {
	color: #76838f;
	font-size: 20px;
}

.files-info {
	padding: 0 30px 0 50px;
}

.file-name a {
	color: #333;
}

.files-action {
	display: none;
	height: 30px;
	list-style: none;
	padding-left: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 10px;
	width: 30px;
}

.files-list>li:hover .files-action {
	display: block;
}

.file-date {
	color: #888;
	font-size: 12px;
}

.file-author a {
	color: #0d6efd;
	font-size: 12px;
	text-decoration: underline;
}

.files-action .dropdown-menu {
	left: auto;
	right: 0;
}

.files-action>li>a {
	color: #777;
}

.chat-img-attach {
	border-radius: 4px;
	margin-bottom: 12px;
	margin-left: 12px;
	position: relative;
	float: right;
}

.chat-img-attach img {
	border-radius: 4px;
	display: inline-block;
	height: auto;
	max-width: 100%;
}

.chat-placeholder {
	background: rgba(69, 81, 97, 0.6);
	border-radius: 4px;
	color: #fff;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	left: 0;
	padding: 13px;
	position: absolute;
	top: 0;
	width: 100%;
}

.chat-img-name {
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
}

.chat-file-desc {
	font-size: 11px;
}

.chat-right .chat-content.img-content {
	background-color: transparent;
	border: 0;
	color: #76838f;
	padding: 0;
	text-align: right;
}

.chat-right .img-content .chat-time {
	color: #a3a3a3;
}

.chat-left .chat-img-attach {
	float: left;
}

.chat-left .chat-img-attach {
	float: left;
	margin-left: 0;
	margin-right: 12px;
}

.input-group .form-control-lg.form-control {
	border-radius: 4px 0 0 4px;
	height: 46px;
}

.chat-user-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.chat-user-list .media {
	border-bottom: 1px solid #eaeaea;
	border-radius: inherit;
	padding: 10px;
	background-color: #fff;
}

.chat-user-list .media:hover {
	background-color: #f7f7f7;
}

.designation {
	color: #9e9e9e;
	font-size: 12px;
}

.online-date {
	color: #9e9e9e;
	font-size: 12px;
}

.drop-zone {
	width: 100%;
	height: 205px;
	border: 2px dashed #adb7be;
	text-align: center;
	padding: 25px 0 0;
	margin-bottom: 20px;
}

.drop-zone .drop-zone-caption {
	font-weight: 600;
}

.upload-list {
	padding: 0;
	list-style: none;
	margin: 0;
}

.upload-list .file-list {
	background-color: #fff;
	border-top: 1px solid #e3e3e3;
	padding: 10px 0;
}

.upload-list .file-list:first-child {
	border-top: none
}

.upload-list .upload-wrap {
	position: relative;
	padding: 0 20px 0 0;
	margin: 0 0 5px
}

.upload-list .file-name,
.upload-list .file-size {
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
}

.upload-list .file-name {
	padding-right: 15px;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis
}

.upload-list .file-size {
	color: #888;
}

.upload-list .file-close {
	border: none;
	background: none;
	color: #ccc;
	position: absolute;
	right: 0;
	top: 2px;
}

.upload-list .file-close:hover {
	color: #f62d51
}

.upload-list .upload-process {
	font-size: 10px;
	color: #888;
}

.upload-list .progress {
	margin-bottom: 5px;
}

.upload-list .file-name i {
	color: #888;
	margin: 0 5px 0 0;
	vertical-align: middle;
}

.upload-drop-zone {
	background-color: #fff;
	border: 2px dashed #e3e3e3;
	color: #ccc;
	height: 200px;
	line-height: 200px;
	margin-bottom: 20px;
	text-align: center;
}

.upload-drop-zone.drop {
	color: #222;
	border-color: #222;
}

.upload-text {
	font-size: 24px;
	margin-left: 10px;
}

.files-share-list {
	border: 1px solid #eaeaea;
	border-radius: 4px;
	margin-bottom: 20px;
	padding: 15px;
}


/*-----------------
  20. Focus Label
-----------------------*/

.form-focus {
	height: 50px;
	position: relative;
}

.form-focus .focus-label {
	font-size: 16px;
	font-weight: 400;
	opacity: 0.4;
	pointer-events: none;
	position: absolute;
	-webkit-transform: translate3d(0, 22px, 0) scale(1);
	-ms-transform: translate3d(0, 22px, 0) scale(1);
	-o-transform: translate3d(0, 22px, 0) scale(1);
	transform: translate3d(0, 22px, 0) scale(1);
	transform-origin: left top;
	transition: 240ms;
	left: 12px;
	top: -8px;
	z-index: 1;
	color: #888;
	margin-bottom: 0;
}

.form-focus.focused .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -14px;
	font-size: 12px;
	z-index: 1;
}

.form-focus .form-control:focus~.focus-label,
.form-focus .form-control:-webkit-autofill~.focus-label {
	opacity: 1;
	font-weight: 300;
	top: -14px;
	font-size: 12px;
	z-index: 1;
}

.form-focus .form-control {
	height: 50px;
	padding: 21px 12px 6px;
}

.form-focus .form-control::-webkit-input-placeholder {
	color: transparent;
	transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
	transition: none;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
	color: #bbb;
}

.form-focus.select-focus .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -20px;
	font-size: 12px;
	z-index: 1;
}

.form-focus .select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	height: 50px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 48px;
	right: 7px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}

.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 12px;
	padding-top: 10px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 38px;
}

.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #fc6075;
}


.filter-row .btn {
	min-height: 50px;
	padding: 12px;
	text-transform: uppercase;
}

.action-label .label {
	display: inline-block;
	min-width: 85px;
	padding: 0.5em 0.6em;
}

.action-label i {
	margin-right: 3px;
}

.action-label .dropdown-menu .dropdown-item {
	padding: 5px 10px;
}


/*-----------------
  22. Employee
-----------------------*/

.action-icon {
	color: #777;
	font-size: 18px;
	display: inline-block;
}

.table .dropdown-menu {
	font-size: 13px;
	min-width: 120px;
	padding: 0;
}

.table .dropdown-menu .dropdown-item {
	padding: 5px 10px;
}

.dropdown-action .dropdown-toggle::after {
	display: none;
}

.profile-widget {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 20px;
	text-align: center;
	position: relative;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.profile-widget .user-name>a {
	color: #333;
}

.dropdown.profile-action {
	position: absolute;
	right: 5px;
	text-align: right;
	top: 10px;
}

.profile-action .dropdown-toggle::after {
	display: none;
}

.profile-img {
	cursor: pointer;
	height: 80px;
	margin: 0 auto;
	position: relative;
	width: 80px;
}

.profile-img .avatar {
	font-size: 24px;
	height: 80px;
	line-height: 80px;
	margin: 0;
	width: 80px;
}

.mobile-no>a {
	color: #777;
	display: inline-block;
}

.staff-mail>a {
	color: #777;
	display: inline-block;
	margin-top: 5px;
}

.staff-action-btns {
	margin-top: 10px;
}

.staff-id {
	font-size: 14px;
	font-weight: 500;
	margin-top: 5px;
}

.view-icons {
	float: right;
	margin-right: 10px;
}

.view-icons .btn {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	color: #888;
	font-size: 18px;
	margin-right: 5px;
	min-width: 40px;
	padding: 4px;
}

.view-icons .btn.active {
	color: #333;
}


/*-----------------
  23. Events
-----------------------*/

.calendar {
	float: left;
	margin-bottom: 0;
}

.fc-view {
	margin-top: 30px;
}

.none-border .modal-footer {
	border-top: none;
}

.fc-toolbar h2 {
	font-size: 18px;
	font-weight: 600;
	font-family: 'Inter', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
	font-family: 'Inter', sans-serif;
}

.fc-day {
	background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}

.fc th.fc-widget-header {
	background: #eeeeee;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}

.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}

.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}

.fc-state-hover {
	background: #f3f3f3;
}

.fc-state-highlight {
	background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #0d6efd !important;
	color: #fff !important;
	text-shadow: none !important;
}

.fc-cell-overlay {
	background: #f0f0f0;
}

.fc-unthemed .fc-today {
	background: #fff;
}

.fc-event {
	border-radius: 2px;
	border: none;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}

.external-event {
	cursor: move;
	margin: 10px 0;
	padding: 6px 10px;
}

.external-event.bg-primary {
	background-color: rgba(127, 193, 252, 0.3) !important;
	color: #7fc1fc;
}

.external-event.bg-success {
	background-color: rgba(75, 211, 150, 0.3) !important;
	color: #4bd396;
}

.external-event.bg-info {
	background-color: rgba(58, 201, 214, 0.3) !important;
	color: #3ac9d6;
}

.external-event.bg-warning {
	background-color: rgba(249, 200, 81, 0.3) !important;
	color: #f9c851;
}

.external-event.bg-danger {
	background-color: rgba(245, 112, 122, 0.3) !important;
	color: #f5707a;
}

.external-event.bg-pink {
	background-color: rgba(240, 98, 146, 0.3) !important;
	color: #f06292;
}

.external-event.bg-purple {
	background-color: rgba(107, 95, 181, 0.3) !important;
	color: #6b5fb5;
}

.external-event.bg-inverse {
	background-color: rgba(59, 62, 71, 0.3) !important;
	color: #3b3e47;
}

.external-event.bg-orange {
	background-color: rgba(255, 152, 0, 0.3) !important;
	color: #ff9800;
}

.external-event.bg-brown {
	background-color: rgba(141, 110, 99, 0.3) !important;
	color: #8d6e63;
}

.external-event.bg-teal {
	background-color: rgba(38, 166, 154, 0.3) !important;
	color: #26a69a;
}

.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Inter', sans-serif;
}

.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Inter', sans-serif;
}

.event-form .input-group .form-control {
	height: 40px;
}


/*-----------------
  24. Profile
-----------------------*/

.profile-details {
	text-align: center;
}

.personal-info li .title {
	color: #4f4f4f;
	float: left;
	font-weight: 500;
	margin-right: 30px;
	width: 25%;
}

.personal-info li .text {
	color: #8e8e8e;
	display: block;
	overflow: hidden;
}

.personal-info li {
	margin-bottom: 10px;
}

.personal-info {
	list-style: none;
	margin-bottom: 0;
	padding: 0;
}

.profile-view {
	position: relative;
}

.profile-view .profile-img-wrap {
	height: 120px;
	width: 120px;
}

.profile-view .profile-img {
	width: 120px;
	height: 120px;
}

.profile-view .profile-img .avatar {
	font-size: 24px;
	height: 120px;
	line-height: 120px;
	margin: 0;
	width: 120px;
}

.profile-view .profile-basic {
	margin-left: 140px;
	padding-right: 50px;
}

.profile-view .pro-edit {
	position: absolute;
	right: 0;
	top: 0;
}

.edit-icon {
	background-color: #eee;
	border: 1px solid #e3e3e3;
	border-radius: 24px;
	color: #bbb;
	float: right;
	font-size: 12px;
	line-height: 24px;
	min-height: 26px;
	text-align: center;
	width: 26px;
}

.edit-icon:hover {
	background-color: #0d6efd;
	border-color: #0d6efd;
	color: #fff;
}

.delete-icon {
	color: #e30b0b;
	float: right;
	font-size: 18px;
}

.delete-icon:hover {
	color: #e30b0b;
}

.staff-msg {
	margin-top: 30px;
}

.experience-box {
	position: relative;
}

.experience-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.experience-list::before {
	background: #ddd;
	bottom: 0;
	content: "";
	left: 8px;
	position: absolute;
	top: 8px;
	width: 2px;
}

.experience-list>li {
	position: relative;
}

.experience-list>li:last-child .experience-content {
	margin-bottom: 0;
}

.experience-user .avatar {
	height: 32px;
	line-height: 32px;
	margin: 0;
	width: 32px;
}

.experience-list>li .experience-user {
	background: #fff;
	height: 10px;
	left: 4px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 4px;
	width: 10px;
}

.experience-list>li .experience-content {
	background-color: #fff;
	margin: 0 0 20px 40px;
	padding: 0;
	position: relative;
}

.experience-list>li .experience-content .timeline-content {
	color: #9e9e9e;
}

.experience-list>li .experience-content .timeline-content a.name {
	color: #616161;
	font-weight: bold;
}

.experience-list>li .time {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 1.35;
}

.before-circle {
	background-color: #ddd;
	border-radius: 50%;
	height: 10px;
	width: 10px;
}

.skills>span {
	border: 1px solid #ccc;
	border-radius: 500px;
	display: inline-block;
	margin-bottom: 10px;
	padding: 6px 12px;
	text-align: center;
}

.profile-info-left {
	border-right: 2px dashed #ccc;
}

.bootstrap-tagsinput {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 0;
	box-shadow: unset;
	display: block;
	min-height: 44px;
	padding: 6px 6px 0;
}

.bootstrap-tagsinput .badge {
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 6px;
	padding: 10px 15px;
}

.add-more a {
	color: #0d6efd;
}

.add-more a:hover {
	color: #ff851a;
}

.avatar-box {
	float: left;
}

.pro-overview .personal-info li .title {
	width: 50%;
}

/*.profile-box {
	min-height: 250px;
}

*/
/*-----------------
  25. Notifications
-----------------------*/

.notifications {
	padding: 0;
}

.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}

.notifications .notification-heading {
	margin: 0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.45;
	color: #616161;
}

.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}

.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.notifications ul.notification-list>li {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}

.notifications ul.notification-list>li:last-child {
	border-bottom: none;
}

.notifications ul.notification-list>li a {
	display: block;
	padding: 12px;
	border-radius: 2px;
}

.notifications ul.notification-list>li a:hover {
	background-color: #fafafa;
}

.notifications ul.notification-list>li .list-item {
	border: 0;
	padding: 0;
	position: relative;
}

.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}

.notifications .media:last-child {
	border-bottom: none;
}

.notifications .media a {
	display: block;
	padding: 12px;
	border-radius: 2px;
}

.notifications .media a:hover {
	background-color: #fafafa;
}

.notifications .media-list .media-left {
	padding-right: 8px;
}

.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
	font-size: 12px;
	height: 32px;
	line-height: 32px;
	padding-left: 12px;
	padding-right: 12px;
}

.topnav-dropdown-header .notification-title {
	color: #333;
	display: block;
	float: left;
	font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
	color: #f83f37;
	float: right;
	font-size: 11px;
	text-transform: uppercase;
}

.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}

.user-menu.nav>li>a .badge {
	color: #fff;
	font-weight: 700;
	position: absolute;
	right: 3px;
	top: 6px;
}

.user-menu.nav>li>a .badge {
	background: linear-gradient(to right, #0dcaf0, #0d6efd);
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
	background: none;
}

.user-menu.nav>li>a>i {
	font-size: 18px;
	line-height: 60px;
}

.noti-details {
	color: #989c9e;
	margin-bottom: 0;
}

.noti-title {
	color: #333;
}

.noti-time {
	margin: 0;
}


/*-----------------
  27. Chat Right Sidebar
-----------------------*/

.fixed-header .custom-menu {
	margin: 1px 0 0;
}

.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}

.list-box {
	list-style: none;
	padding-left: 0;
}

.list-item {
	border-bottom: 1px solid #eaeaea;
	padding: 10px;
	position: relative;
}

.list-left {
	height: 48px;
	position: absolute;
	width: 48px;
}

.files-icon {
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	border-radius: 4px;
	display: inline-block;
	height: 38px;
	line-height: 38px;
	text-align: center;
	width: 38px;
}

.files-icon i {
	color: #777;
	font-size: 20px;
}

.list-body {
	padding: 0 0 0 50px;
}

.message-author {
	color: #333;
	float: left;
	font-weight: 500;
	width: 175px;
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.message-time {
	color: #888;
	float: right;
	font-size: 11px;
}

.message-content {
	color: #333;
	font-size: 13px;
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.files-action .dropdown-menu {
	left: auto;
	right: 0;
}


/*-----------------
  30. Task
-----------------------*/

.task-chat-contents {
	background-color: #fff;
}

.task-chat-contents .chat-left .chat-content {
	max-width: 100%;
}

.task-chat-view {
	border-left: 1px solid #eaeaea;
}

.task-left-sidebar {
	width: 58.3333%;
}

.task-right-sidebar {
	width: 41.6667%;
}

.task-chat-view .chat-left .chat-content {
	border: 0;
	padding: 0;
}

.task-chat-view .chat-left .chat-body {
	margin-left: 50px;
}

.task-chat-view .chat-date {
	background-color: #fff;
}

.task-chat-view .chats {
	padding: 15px 15px 30px;
}

.task-chat-view .avatar {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.followers-add {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	color: #ccc;
	display: inline-block;
	font-size: 20px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}

.followers-add:hover {
	border: 1px solid #8c8c8c;
	color: #8c8c8c;
}

.completed-task-msg {
	margin-bottom: 30px;
	margin-left: 50px;
}

.task-chat-contents .chat.chat-left {
	margin-bottom: 20px;
}

.file-attached {
	color: #333;
	font-size: 13px;
}

.file-attached i {
	font-size: 16px;
}

.task-attach-img>img {
	border: 1px solid #eaeaea;
	max-width: 100%;
}

.attach-img-download {
	margin-bottom: 5px;
}

.task-chat-view .chat-left .chat-time {
	color: #a3a3a3;
	display: inline-block;
	font-size: 11px;
}

.task-chat-user {
	color: #333;
	font-size: 13px;
}

.task-chat-view .chat-content>p {
	font-size: 13px;
	margin-bottom: 0;
}

.task-time {
	color: #a3a3a3;
	display: inline-block;
	font-size: 11px;
}

.task-success {
	color: #55ce63;
	font-size: 13px;
}

.task-success a {
	color: #55ce63;
}

.task-attach-img {
	margin-bottom: 20px;
}

.task-assign {
	float: left;
}

.assign-title {
	float: left;
	margin-right: 10px;
	margin-top: 5px;
}

.task-assign>a {
	float: left;
}

.followers-add>i {
	line-height: 28px;
}

.task-followers {
	display: flex;
	margin-top: 15px;
}

.followers-title {
	margin: 5px 10px 0 0;
}

.task-line {
	border-color: #eaeaea;
}

.task-information {
	color: #a3a3a3;
	font-size: 11px;
	line-height: 17px;
	min-width: 1px;
	margin-left: 50px;
}

.task-info-line {
	overflow-wrap: break-word;
}

.task-user {
	color: #888;
	font-weight: 500;
}

.task-information .task-time {
	display: inline-block;
	padding-left: 10px;
}

.task-btn-right {
	display: none !important;
}

.task:hover .task-btn-right {
	display: table-cell !important;
}

.task-action-btn.task-check {
	text-align: left !important;
	width: 40px;
}

.add-task-btn {
	cursor: pointer;
	padding: 6px 10px;
	text-transform: uppercase;
}

.action-circle {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 100%;
	height: 20px;
	width: 20px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.action-circle .material-icons {
	color: #ccc;
	font-size: 18px;
	vertical-align: -3px;
}

.action-circle.completed {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.action-circle.completed .material-icons {
	color: #fff;
}

.action-circle.large {
	height: 24px;
	width: 24px;
}

.action-circle.large .material-icons {
	font-size: 16px;
	vertical-align: -4px;
}

.task-wrapper {
	padding: 20px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
}

.task-wrapper .mark-all-tasks {
	text-align: right;
	padding-bottom: 15px;
}

.task-wrapper .mark-all-tasks .mark-all-tasks-container {
	overflow: hidden;
	position: relative;
}

.task-wrapper .mark-all-tasks .mark-all-btn {
	cursor: pointer;
	display: inline-block;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-finished {
	color: #35BA67;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete {
	color: #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle {
	border: 1px solid #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle .material-icons {
	color: #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn.move-up {
	-webkit-transform: translateY(-30px);
	-moz-transform: translateY(-30px);
	-ms-transform: translateY(-30px);
	-o-transform: translateY(-30px);
	transform: translateY(-30px);
	position: absolute;
	right: 0;
}

.task-wrapper .mark-all-tasks .mark-all-btn.move-down {
	-webkit-transform: translateY(30px);
	-moz-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
	transform: translateY(30px);
	position: absolute;
	right: 0;
}

.task-wrapper .task-list-header h3,
.task-wrapper .task-list-footer h3 {
	font-size: 24px;
	font-weight: 400;
	color: #666;
}

.task-wrapper .task-list-body {
	max-height: 500px;
	overflow: auto;
}

.task-wrapper #task-list {
	list-style: none;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #eaeaea;
}

.task-wrapper #task-list li {
	margin: 0;
	padding: 0;
}

.task-wrapper #task-list li .task-container {
	display: table;
	background: #fff;
	padding: 8px 15px;
	width: 100%;
	border: 1px solid #eaeaea;
	border-bottom: none;
	box-sizing: border-box;
	position: relative;
	-webkit-transition: background 200ms ease;
	-moz-transition: background 200ms ease;
	-ms-transition: background 200ms ease;
	-o-transition: background 200ms ease;
	transition: background 200ms ease;
}

.task-wrapper #task-list li .task-container .task-label {
	display: table-cell;
	font-weight: 400;
	vertical-align: middle;
	color: #333;
	word-break: break-all;
}

.task-wrapper #task-list li .task-container .task-action-btn {
	display: table-cell;
	vertical-align: middle;
	text-align: right;
}

.task-wrapper #task-list li .task-container .task-action-btn .action-circle:hover {
	border: 1px solid #8c8c8c;
	background: #fff;
}

.task-wrapper #task-list li .task-container .task-action-btn .action-circle:hover .material-icons {
	color: #8c8c8c;
}

.task-wrapper #task-list li .task-container:hover {
	background: #EBEEEF;
}

.task-wrapper #task-list li.completed .task-container {
	background: #f9f9f9;
}

.task-wrapper #task-list li.completed .task-container .complete-btn {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.task-wrapper #task-list li.completed .task-container .complete-btn .material-icons {
	color: #fff;
}

.task-wrapper #task-list li.completed .task-container .complete-btn:hover {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.task-wrapper #task-list li.completed .task-container .complete-btn:hover .material-icons {
	color: #fff;
}

.task-wrapper #task-list li.completed .task-container .task-label {
	color: #ccc;
}

.task-wrapper #task-list li.new .task-container {
	-webkit-animation: taskHighlighter 2000ms linear 1;
	animation: taskHighlighter 2000ms linear 1;
}

.task-wrapper .task-list-footer {
	position: relative;
}

.task-wrapper .task-list-footer .add-task-btn-wrapper {
	text-align: center;
}

.task-wrapper .task-list-footer .add-task-btn {
	cursor: pointer;
	border: 2px solid #9B1BCC;
	display: inline-block;
	height: 35px;
	width: 35px;
	border-radius: 10px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.task-wrapper .task-list-footer .add-task-btn.hide {
	opacity: 1;
	visibility: visible;
	display: block;
}

.task-wrapper .task-list-footer .add-task-btn:hover {
	background: #9B1BCC;
}

.task-wrapper .task-list-footer .add-task-btn:hover .material-icons {
	color: #EBEEEF;
}

.task-wrapper .task-list-footer .add-task-btn .material-icons {
	color: #9B1BCC;
	font-size: 34px;
}

.task-wrapper .task-list-footer .new-task-wrapper {
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	max-height: 0;
	overflow: hidden;
}

.task-wrapper .task-list-footer .new-task-wrapper::after {
	content: "";
	display: block;
	clear: both;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea {
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #eaeaea;
	border-top: 0;
	height: 45px;
	resize: none;
	padding: 10px 15px;
	margin-bottom: 20px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea:focus {
	border: 1px solid #bfbfbf;
	outline: none;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea.error {
	border: 1px solid #D93737;
	outline: none;
}

.task-wrapper .task-list-footer .new-task-wrapper .error-message {
	color: #D93737;
	font-style: italic;
}

.task-wrapper .task-list-footer .new-task-wrapper .btn {
	padding: 6px 12px;
	float: right;
	cursor: pointer;
}

.task-wrapper .task-list-footer .new-task-wrapper .add-new-task-btn {
	background: #fff;
	border: 1px solid #ccc;
}

.task-wrapper .task-list-footer .new-task-wrapper.visible {
	max-height: 300px;
	overflow: auto;
}

.notification-popup {
	z-index: 1051;
	position: fixed;
	top: 20px;
	right: 10px;
	width: 300px;
	display: inline-block;
	background: #1F8FEF;
	border: 1px solid #1082e4;
	color: #fff;
	padding: 20px;
	opacity: .8;
	border-radius: 2px;
	box-sizing: border-box;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.notification-popup.success {
	background: #35BA67;
	border: 1px solid #2fa65c;
}

.notification-popup p {
	margin-top: 0;
	margin-bottom: 0px;
}

.notification-popup .task {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;
	display: inline-block;
}

.notification-popup .notification-text {
	font-size: 14px;
	display: inline-block;
	overflow: hidden;
}

.notification-popup.hide {
	opacity: 0;
	visibility: hidden;
}

.sidebar-overlay {
	display: none;
	position: fixed;
	z-index: 90;
	height: 100%;
	left: 0;
	top: 60px;
	width: 100%;
	z-index: 1040;
	background-color: rgba(0, 0, 0, 0.6);
}

.sidebar-overlay.opened {
	display: block;
}

.sidebar-menu.opened {
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	-ms-transform: translateX(0px);
	-o-transform: translateX(0px);
	transform: translateX(0px);
	margin-left: 0;
}

html.menu-opened {
	overflow: hidden;
}

html.menu-opened body {
	overflow: hidden;
}

.task-chat-view.opened {
	margin-right: 0;
}

.chat-profile-view.opened {
	margin-right: 0;
}

.task-header {
	display: flex;
}

.task-header .assignee-info {
	align-items: center;
	border: 1px solid transparent;
	border-radius: 10px;
	display: inline-flex;
	flex: 0 1 180px;
	padding: 5px 10px 5px 5px;
	position: relative;
}

.task-header .assignee-info>a {
	align-items: center;
	color: #333;
	display: flex;
}

.task-header .task-head-title {
	color: #8e8e8e;
	font-size: 12px;
}

.task-assignee {
	font-size: 13px;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.assignee-info:hover {
	border-color: #e3e3e3;
}

.remove-icon {
	align-items: center;
	background-color: #e3e3e3;
	border-radius: 50%;
	color: #333;
	cursor: pointer;
	display: flex;
	height: 20px;
	justify-content: center;
	margin-right: 10px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	visibility: hidden;
	width: 20px;
}

.remove-icon:hover {
	background-color: #d3d3d3;
}

.task-header .assignee-info:hover .remove-icon {
	visibility: visible;
}

.remove-icon i {
	color: #8e8e8e;
	font-size: 12px;
}

.due-icon span {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	color: #ccc;
	display: flex;
	height: 30px;
	justify-content: center;
	line-height: 30px;
	margin-right: 10px;
	width: 30px;
}

.due-icon span i {
	font-size: 18px;
}

.task-due-date {
	align-items: center;
	border: 1px solid transparent;
	border-radius: 10px;
	display: flex;
	flex: 0 0 160px;
	margin-left: 10px;
	padding: 5px 10px 5px 5px;
	position: relative;
}

.task-due-date:hover {
	border-color: #e3e3e3;
}

.task-header .task-due-date>a {
	align-items: center;
	color: #333;
	display: flex;
}

.due-info .due-date {
	color: #f62d51;
	font-size: 13px;
}

.task-header .task-due-date:hover .remove-icon {
	visibility: visible;
}

.task-desc {
	align-items: flex-start;
	display: flex;
	margin-bottom: 15px;
	margin-top: 5px;
}

.task-textarea {
	display: flex;
	width: 100%;
}

.task-desc-icon {
	color: #ccc;
	margin-right: 10px;
	margin-top: 5px;
}

.task-textarea .form-control {
	border: 1px solid transparent;
	border-radius: 5px;
	font-size: 13px;
	resize: none;
}

.task-textarea:hover .form-control {
	border-color: #e3e3e3;
}

.task-complete-btn {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	color: #333;
	display: flex;
	font-size: 14px;
	padding: 6px 10px;
}

.task-complete-btn:hover {
	background-color: #f7f7f7;
	color: #333;
}

.task-complete-btn i {
	font-size: 20px;
	margin-right: 3px;
}

.task-completed {
	background-color: #35ba67;
	border-color: #2fa65c;
	color: #fff;
}

.task-completed:hover {
	background-color: #35ba67;
	border-color: #2fa65c;
	color: #fff;
}


.dataTables_wrapper.container-fluid {
	padding-left: 0;
	padding-right: 0;
}


/*-----------------
  36. Inbox
-----------------------*/

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
	cursor: pointer;
}

.mail-list {
	list-style: none;
	padding: 0;
}

.mail-list>li>a {
	color: #333;
	display: block;
	padding: 10px;
}

.mail-list>li.active>a {
	color: #0d6efd;
	font-weight: bold;
}

.unread .name,
.unread .subject,
.unread .mail-date {
	color: #000;
	font-weight: 600;
}

.table-inbox .fa-star {
	color: #ffd200;
}

.table-inbox .starred.fa-star {
	color: #ffd200;
}

.table.table-inbox>tbody>tr>td,
.table.table-inbox>tbody>tr>th,
.table.table-inbox>tfoot>tr>td,
.table.table-inbox>tfoot>tr>th,
.table.table-inbox>thead>tr>td,
.table.table-inbox>thead>tr>th {
	border-bottom: 1px solid #f2f2f2;
	border-top: 0;
}

.table-inbox {
	font-size: 14px;
	margin-bottom: 0;
}

.note-editor.note-frame {
	border: 1px solid #ccc;
	box-shadow: inherit;
}

.note-editor.note-frame .note-statusbar {
	background-color: #fff;
}

.note-editor.note-frame.fullscreen {
	top: 60px;
}

.mail-title {
	font-weight: bold;
	text-transform: uppercase;
}

.form-control.search-message {
	border-radius: 4px;
	height: 38px;
	margin-left: 5px;
	width: 180px;
}

.table-inbox tr {
	cursor: pointer;
}

table.table-inbox tbody tr.checked {
	background-color: #ffffcc;
}

.mail-label {
	font-size: 16px !important;
	margin-right: 5px;
}


/*-----------------
  37. Mail View
-----------------------*/

.attachments {
	list-style: none;
	margin: 0;
	padding: 0;
}

.attachments li {
	border: 1px solid #eee;
	float: left;
	margin-bottom: 10px;
	margin-right: 10px;
	width: 200px;
}

.attach-file {
	color: #777;
	font-size: 70px;
	padding: 10px;
	text-align: center;
	min-height: 153px;
}

.attach-file>i {
	line-height: 133px;
}

.attach-info {
	background-color: #f4f4f4;
	padding: 10px;
}

.attach-filename {
	color: #777;
	font-weight: bold;
}

.attach-filesize {
	color: #999;
	font-size: 12px;
}

.attach-file img {
	height: auto;
	max-width: 100%;
}

.mailview-header {
	border-bottom: 1px solid #ddd;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.mailview-footer {
	border-top: 1px solid #ddd;
	margin-top: 20px;
	padding-top: 15px;
}

.mailview-footer .btn-white {
	min-width: 102px;
}

.sender-img {
	float: left;
	margin-right: 10px;
	width: 40px;
}

.sender-name {
	display: block;
}

.receiver-name {
	color: #777;
}

.right-action {
	text-align: right;
}

.mail-view-title {
	font-weight: 500;
	font-size: 24px;
	margin: 0;
}

.mail-view-action {
	float: right;
}

.mail-sent-time {
	float: right;
}

/*-----------------
  43. Chat Sidebar
-----------------------*/

.chat-sidebar {
	background-color: #fff;
	border-left: 1px solid #eaeaea;
	width: 300px;
}

.chat-sidebar .chat-contents {
	background-color: #fff;
}

.chat-sidebar .chat-left .chat-content {
	border: 0;
	padding: 0;
	border-radius: 0 !important;
	background-color: transparent;
	max-width: 100%;
}

.chat-sidebar .chat-left .chat-body {
	margin-left: 50px;
	padding-right: 0;
}

.chat-sidebar .chat-date {
	background-color: #fff;
}

.chat-sidebar .chats {
	padding: 15px 15px 30px;
}

.chat-sidebar .avatar {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.chat-sidebar .chat.chat-left {
	margin-bottom: 20px;
}

.chat-sidebar .chat.chat-left:last-child {
	margin-bottom: 0;
}

.chat-sidebar .chat-left .chat-time {
	color: #888;
	display: inline-block;
	font-size: 11px;
}

.chat-sidebar .chat-content>p {
	font-size: 13px;
	margin-bottom: 0;
}

.chat-sidebar.opened {
	margin-right: 0;
}



/*-----------------
  46. Notification Settings
-----------------------*/

.notification-list .list-group-item {
	padding: 15px;
}

.notification-list .status-toggle {
	float: right;
}


/*-----------------
  48. Termination
-----------------------*/

.add-group-btn {
	display: flex;
}

.add-group-btn .btn {
	align-items: center;
	display: flex;
	margin-left: 10px;
}

.add-group-btn .btn i {
	margin-right: 5px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #0d6efd;
	text-shadow: unset;
}


/*-----------------
  49. Loader
-----------------------*/

#loader-wrapper {
	background-color: #fff;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
}

#loader {
	background-position: center center;
	background-repeat: no-repeat;
	height: 200px;
	left: 50%;
	margin: -100px 0 0 -100px;
	position: absolute;
	top: 50%;
	width: 200px;
}

.loader-ellips {
	font-size: 20px;
	position: relative;
	width: 64px;
	margin: 100px auto 0;
}

.loader-ellips__dot {
	display: block;
	width: 1em;
	height: 1em;
	border-radius: 0.5em;
	position: absolute;
	animation-duration: 0.5s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
	left: 0;
}

.loader-ellips__dot:nth-child(3) {
	left: 1.5em;
}

.loader-ellips__dot:nth-child(4) {
	left: 3em;
}

.loader-ellips__dot:nth-child(1) {
	animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
	animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
	animation-name: reveal;
	animation-direction: reverse;
}

@keyframes reveal {
	from {
		transform: scale(0.001);
	}
	to {
		transform: scale(1);
	}
}

@keyframes slide {
	to {
		transform: translateX(1.5em)
	}
}

.loader-ellips__dot:nth-child(1) {
	background: #404040;
}

.loader-ellips__dot:nth-child(2) {
	background: #0d6efd;
}

.loader-ellips__dot:nth-child(3) {
	background: #404040;
}

.loader-ellips__dot:nth-child(4) {
	background: #404040;
}


/*-----------------
  51. Error
-----------------------*/

.error-page {
	align-items: center;
	background-color: rgba(238, 238, 238, 0.2);
	color: #1f1f1f;
	display: flex;
}

.error-page .main-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.error-box {
	margin: 0 auto;
    max-width: 480px;
    padding: 40px 0;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-box h1 {
	color: #0d6efd;
	font-size: 10em;
}

.error-box p {
	margin-bottom: 30px;
}

.error-box .btn {
	border-radius: 50px;
	font-size: 18px;
	font-weight: 600;
	min-width: 200px;
	padding: 10px 20px;
}


.settings-widget+.settings-widget {
	margin-top: 30px;
}

.row.row-sm {
	margin-left: -5px;
	margin-right: -5px;
}

.row.row-sm>div {
	padding-left: 5px;
	padding-right: 5px;
}

.set-btn {
	min-height: 44px;
}


/*-----------------
  55. Components
-----------------------*/

.comp-buttons .btn {
	margin-bottom: 5px;
}

.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}

.progress-example .progress {
	margin-bottom: 1.5rem;
}

.progress-xs {
	height: 4px;
}

.progress-sm {
	height: 15px;
}

.progress.progress-sm {
	height: 6px;
}

.progress.progress-md {
	height: 8px;
}

.progress.progress-lg {
	height: 18px;
}

.stickyside .sidebar-menu>ul>li>a.active {
	color: #fff;
}

.comp-section {
	padding-top: 30px;
}

.comp-section:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

.comp-section .section-title {
	font-size: 1.125rem;
	margin-bottom: 20px;
	text-decoration: underline;
	text-transform: uppercase;
}


/*-----------------
  56. Search
-----------------------*/

.main-search {
	margin-bottom: 20px;
}

.search-result {
	margin-bottom: 30px;
}

.search-result u {
	color: #0d6efd;
}

.search-result p {
	color: #777;
	margin-bottom: 0;
}

.search-lists .nav-tabs.nav-tabs-solid {
	background-color: #fff;
	border: 1px solid #e3e3e3;
}

.top-nav-search form {
	margin-top: 10px;
	position: relative;
	width: 230px;
}

.top-nav-search .form-control {
	border-radius: 50px;
	height: 40px;
	padding: 10px 50px 10px 15px;
}

.top-nav-search .btn {
	background-color: transparent;
	border-color: transparent;
	color: #999;
	min-height: 40px;
	padding: 7px 15px;
	position: absolute;
	right: 0;
	top: 0;
}

.top-nav-search .form-control::-webkit-input-placeholder {
	color: #999;
}

.top-nav-search .form-control::-moz-placeholder {
	color: #999;
}

.top-nav-search .form-control:-ms-input-placeholder {
	color: #999;
}

.top-nav-search .form-control::-ms-input-placeholder {
	color: #999;
}

.top-nav-search .form-control::placeholder {
	color: #999;
}

.responsive-search {
	display: none;
	color: #333;
	font-size: 20px;
	height: 60px;
	line-height: 60px;
	padding: 0 15px;
}

.top-nav-search.active form {
	display: block;
	left: 0;
	position: absolute;
}

/*-----------------
  58. FAQ
-----------------------*/

.faq-card .card-header {
	background-color: #fff;
	padding: 20px;
	position: relative;
}

.faq-card .card {
	border-color: #ddd;
	border-radius: 3px;
	box-shadow: none;
	margin-bottom: 20px;
	overflow: hidden;
}

.faq-card .card .card-header h4 {
	margin-bottom: 0;
}

.faq-card .card .card-header h4>a {
	color: #090909;
	display: block;
	font-size: 16px;
	font-weight: 400;
	padding-right: 60px;
	position: relative;
}

.faq-card .card .card-header h4>a:after {
	font-family: FontAwesome;
	font-size: 19px;
	position: absolute;
	right: 0px;
	font-weight: 400;
}

.faq-card .card .card-header h4>a.collapsed:after {
	content: "\f067";
}

.faq-card .card .card-header h4>a:not(.collapsed):after {
	content: "\f068";
}

.faq-card .card .card-header h4>label {
	color: #fff;
	cursor: pointer;
	display: flex;
	font-size: 24px;
	font-weight: 400;
	margin: 0;
	padding-right: 60px;
	position: relative;
}

.faq-card .card .card-header h4>label:after {
	font-family: FontAwesome;
	font-size: 19px;
	position: absolute;
	right: 0px;
	font-weight: 400;
	top: 0;
}

.faq-card .card .card-header h4>label.collapsed:after {
	content: "\f067";
}

.faq-card .card .card-header h4>label:not(.collapsed):after {
	content: "\f068";
}

.small-container {
	margin: auto;
	max-width: 1100px;
	padding: 30px;
}

.inner-header {
	margin-bottom: 30px;
}



/*-----------------
  61. Kanban Board
-----------------------*/

.card>.task-header {
	line-height: 15px;
	display: flex;
	justify-content: space-between;
}

.task-header {
	font-size: 14px;
	font-weight: 500;
	padding: 15px 15px 5px;
}

.task-header a {
	color: #1f1f1f;
}

.task-body {
	padding: 0 15px 15px;
	font-size: 13px;
}

.kanban-list:last-child {
	margin-right: 0;
}

.kanban-list>.kanban-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

.kanban-list>.kanban-header>.status-title {
	color: #fff;
	font-size: 16px;
	font-weight: 500;
}

.kanban-list>.kanban-wrap {
	padding: 20px;
}

.kanban-list>.kanban-wrap>.drag-placeholder {
	height: 146px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.05);
	margin-bottom: 12px;
}

.kanban-list>.kanban-wrap.ks-empty>.card.drop-here {
	display: block;
}

.kanban-list>.kanban-wrap>.card {
	border: 1px solid #e3e3e3;
	border-radius: 2px;
	box-shadow: unset;
	margin: 0 0 12px;
}

.kanban-list>.kanban-wrap>.card.drop-here {
	display: none;
	text-align: center;
	background: transparent;
	padding: 15px;
	border-style: dashed;
}

.kanban-list>.kanban-wrap>.card.drop-here>a {
	color: #333;
	font-weight: 500;
}

.kanban-list>.kanban-wrap>.card:last-child {
	margin-bottom: 0;
}

.kanban-list>.kanban-wrap>.card.ui-sortable-helper {
	box-shadow: 0 2px 20px 0 #d7dceb;
}

.kanban-list>.kanban-wrap>.card>.task-header {
	font-size: 14px;
	font-weight: 500;
	border: none;
	padding: 15px 15px 5px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.kanban-list>.kanban-wrap>.card>.task-body {
	padding: 0 15px 15px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info {
	display: flex;
	align-items: center;
	font-size: 12px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress {
	margin-bottom: 0;
	margin-right: 10px;
	width: 100%;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #42a5f5;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	margin-top: 10px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer .ks-icon {
	color: #d7dceb;
	font-size: 20px;
	display: inline-block;
	margin-right: 3px;
	position: relative;
	top: 1px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer>.task-users>.task-avatar>img {
	width: 24px;
	height: 24px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-footer>.task-users>.ks-amount {
	font-size: 10px;
	font-weight: 500;
	display: inline-block;
	margin-left: 3px;
	position: relative;
	top: 1px;
}

.kanban-list.kanban-danger {
	background-color: #fef7f6;
}

.kanban-list.kanban-danger>.kanban-header {
	background-color: #ef5350;
}

.kanban-list.kanban-danger>.kanban-wrap>.card.drop-here {
	border-color: #f7c1b7;
}

.kanban-list.kanban-danger>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #ef5350;
}

.kanban-list.kanban-success {
	background-color: #edf7ee;
}

.kanban-list.kanban-success>.kanban-header {
	background-color: #4caf50;
}

.kanban-list.kanban-success>.kanban-wrap>.card.drop-here {
	border-color: #abebc6;
}

.kanban-list.kanban-success>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #4caf50;
}

.kanban-list.kanban-info {
	background-color: #e7f3fe;
}

.kanban-list.kanban-info>.kanban-header {
	background-color: #42a5f5;
}

.kanban-list.kanban-info>.kanban-wrap>.card.drop-here {
	border-color: #a6dbf9;
}

.kanban-list.kanban-warning {
	background-color: #fdfcf3;
}

.kanban-list.kanban-warning>.kanban-header {
	background-color: #ffb300;
}

.kanban-list.kanban-warning>.kanban-wrap>.card.drop-here {
	border: 1px solid #f2e49e;
}

.kanban-list.kanban-warning>.kanban-wrap>.card>.kanban-box>.task-body>.kanban-info>.progress .progress-bar {
	background-color: #ffb300;
}

.task-avatar {
	border-radius: 50%;
	position: relative;
}

.kanban-box.ui-sortable-handle {
	cursor: move;
}

.card>.task-board-header {
	line-height: 15px;
	display: flex;
	justify-content: space-between;
}

.task-board-header {
	font-size: 14px;
	font-weight: 500;
	padding: 15px 15px 5px;
	display: flex;
	justify-content: space-between;
}

.task-board-header a {
	color: #1f1f1f;
}

.task-board-body {
	padding: 0 15px 15px;
	font-size: 13px;
}

.progress>.progress-bar {
	background-color: #25628F;
}

.kanban-cont {
	display: flex;
	overflow: auto;
	padding-bottom: 20px;
}

.kanban-list {
	align-self: flex-start;
	margin-right: 30px;
	min-width: 300px;
	width: 300px;
}

.kanban-list:last-child {
	margin-right: 0;
}

.kanban-list>.kanban-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

.kanban-list>.kanban-header>.status-title {
	color: #fff;
	font-size: 16px;
	font-weight: 500;
}

.kanban-list>.kanban-wrap {
	padding: 20px;
}

.kanban-list>.kanban-wrap>.drag-placeholder {
	height: 146px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.05);
	margin-bottom: 12px;
}

.kanban-list>.kanban-wrap>.card {
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 2px;
	box-shadow: unset;
	margin: 0 0 12px;
}

.kanban-list>.kanban-wrap>.card.active {
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.kanban-list>.kanban-wrap>.card:last-child {
	margin-bottom: 0;
}

.kanban-list>.kanban-wrap>.card.ui-sortable-helper {
	box-shadow: 0 2px 20px 0 #d7dceb;
}

.kanban-list>.kanban-wrap>.card>.task-board-header {
	font-size: 14px;
	font-weight: 500;
	border: none;
	padding: 15px 15px 5px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.kanban-list>.kanban-wrap>.card>.task-board-body {
	padding: 0 15px 15px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info {
	display: flex;
	align-items: center;
	font-size: 12px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress {
	margin-bottom: 0;
	margin-right: 10px;
	width: 100%;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #42a5f5;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-footer {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	margin-top: 10px;
	align-items: flex-end;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-footer>.task-users>.task-avatar>img {
	width: 24px;
	height: 24px;
}

.kanban-list>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-footer>.task-users>.task-user-count {
	font-size: 10px;
	font-weight: 500;
	display: inline-block;
	margin-left: 3px;
	position: relative;
	top: 1px;
}

.kanban-list.kanban-danger>.kanban-wrap>.card.active {
	border-color: #ef5350;
}

.kanban-list.kanban-success>.kanban-wrap>.card.active {
	border-color: #4caf50;
}

.kanban-list.kanban-info>.kanban-wrap>.card.active {
	border-color: #42a5f5;
}

.kanban-list.kanban-warning>.kanban-wrap>.card.active {
	border-color: #ffb300;
}

.kanban-list.kanban-danger {
	background-color: #fef7f6;
}

.kanban-list.kanban-danger>.kanban-header {
	background-color: #ef5350;
}

.kanban-list.kanban-danger>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #ef5350;
}

.kanban-list.kanban-success {
	background-color: #edf7ee;
}

.kanban-list.kanban-success>.kanban-header {
	background-color: #4caf50;
}

.kanban-list.kanban-success>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #4caf50;
}

.kanban-list.kanban-info {
	background-color: #e7f3fe;
}

.kanban-list.kanban-info>.kanban-header {
	background-color: #42a5f5;
}

.kanban-list.kanban-warning {
	background-color: #fdfcf3;
}

.kanban-list.kanban-warning>.kanban-header {
	background-color: #ffb300;
}

.kanban-list.kanban-warning>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #ffb300;
}

.kanban-list.kanban-purple {
	background-color: #f1effd;
}

.kanban-list.kanban-purple>.kanban-header {
	background-color: #7460ee;
}

.kanban-list.kanban-purple>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #7460ee;
}

.kanban-list.kanban-primary {
	background-color: #fff5ec;
}

.kanban-list.kanban-primary>.kanban-header {
	background-color: #0d6efd;
}

.kanban-list.kanban-primary>.kanban-wrap>.card>.kanban-box>.task-board-body>.kanban-info>.progress .progress-bar {
	background-color: #0d6efd;
}

.task-avatar {
	border-radius: 50%;
	position: relative;
}

.kanban-box.ui-sortable-handle {
	cursor: move;
}

.kanban-action>a {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.3);
	display: inline-flex;
	align-items: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	justify-content: center;
}

.task-board-color .board-color-list {
	display: flex;
	height: 45px;
}

.task-board-color .board-color-list .board-control {
	position: relative;
	display: inline-flex;
	min-height: 1.5rem;
	padding-left: 1.5rem;
	margin-right: 1rem;
	cursor: pointer;
	padding-left: 0;
	cursor: pointer;
	width: 36px;
	height: 36px;
}

.task-board-color .board-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.board-control>.board-control-input:checked~.board-indicator:before {
	content: "\f00c";
	display: block;
	width: 16px;
	height: 16px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	color: #fff;
	border: none;
	position: relative;
	font-family: FontAwesome;
	text-align: center;
	line-height: 16px;
}

.board-control>.board-control-input:checked~.board-indicator::before {
	width: 36px;
	height: 36px;
	line-height: 36px;
}

.board-control>.board-indicator {
	width: 36px;
	height: 36px;
}

.board-control.board-primary>.board-indicator {
	background: #0d6efd;
}

.board-control.board-success>.board-indicator {
	background: #4caf50;
}

.board-control.board-info>.board-indicator {
	background: #42a5f5;
}

.board-control.board-purple>.board-indicator {
	background: #7460ee;
}

.board-control.board-warning>.board-indicator {
	background: #ffb300;
}

.board-control.board-danger>.board-indicator {
	background: #ef5350;
}

.kanban-board.task-wrapper .kanban-list {
	width: 100%;
}

.board-view-header {
	margin-bottom: 30px;
}

.pro-teams {
	display: inline-flex;
	margin-right: 15px;
	text-align: center;
	margin-bottom: 20px;
}

.pro-teams .pro-team-members {
	margin-left: 15px;
	display: flex;
	align-items: center;
}

.pro-teams h4 {
	margin-bottom: 0;
	margin-right: 6px;
	font-size: 16px;
}

.pro-team-lead {
	display: flex;
	align-items: center;
}

.board-view-header .view-icons {
	float: right;
	margin-right: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 2px 7px;
	border-radius: 4px;
}

.board-view-header .view-icons .btn {
	margin: 0;
	padding: 0;
}

.board-view-header .view-icons a.btn+a.btn {
	margin-left: 5px;
}

.pro-progress-bar {
	display: flex;
	align-items: center;
}

.pro-progress-bar h4 {
	margin-bottom: 0;
	margin-right: 10px;
	font-size: 13px;
	text-transform: uppercase;
}

.pro-progress-bar .progress {
	width: 100%;
	margin-bottom: 0;
	margin-right: 10px;
	height: 15px;
	background-color: #e3e3e3;
	box-shadow: unset;
}

.pro-progress-bar .progress>.progress-bar {
	background-color: #0d6efd;
}

.pro-teams .avatar-group {
	display: inline-flex;
}

.pro-teams .avatar-group .avatar {
	margin-right: 0;
	position: relative;
	display: inline-block;
	width: 34px;
	height: 34px;
	background-color: transparent;
}

.pro-teams .avatar-group .avatar+.avatar {
	margin-left: -.75rem;
}

.pro-teams .avatar-group .avatar .border {
	border: 3px solid #fff !important;
}

.pro-teams .avatar-group .avatar>img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 50%;
}

.border-white {
	border-color: #fff !important;
}

.pro-teams .avatar-group .avatar:hover {
	z-index: 1;
}

.pro-teams .avatar .avatar-title {
	font-size: 14px;
	border-radius: 50%;
}

.pro-teams .avatar-title {
	width: 100%;
	height: 100%;
	background-color: #0d6efd;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.task-date {
	display: block;
}

.task-priority {
	padding: .3em .6em .3em;
}

.task-follower-list {
	display: flex;
	margin-top: 15px;
}

.task-follower-list span {
	position: relative;
}

.task-follower-list span i {
	cursor: pointer;
	position: absolute;
	top: -5px;
	right: 5px;
	color: #f00;
}

.add-new-task {
	text-align: center;
	padding: 0 20px 20px;
}

.add-new-task a {
	color: #333;
	font-size: 14px;
}

.project-add-form {
	display: none;
	margin-top: 10px;
}

.project-add-form.opened {
	display: block;
}


/*-----------------
  62. File Manager
-----------------------*/

.file-wrap {
	border: 1px solid #e0e3e4;
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.file-wrap .file-sidebar {
	border-right: 1px solid #e0e3e4;
	left: 0;
	position: absolute;
	z-index: 5;
	width: 300px;
	transition: 0.3s ease-in-out;
}

.file-wrap .file-sidebar .file-header {
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e0e3e4;
	color: #324148;
	display: flex;
	height: 72px;
	justify-content: space-between;
	padding: 0 15px;
}

.file-wrap .file-sidebar .file-header span {
	font-size: 20px;
	font-weight: 600;
	text-transform: capitalize;
}

.file-wrap .file-sidebar .file-search {
	background-color: #f5f5f6;
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 15px;
	width: 100%;
}

.file-wrap .file-sidebar .file-search .input-group {
	width: 100%;
}

.file-wrap .file-sidebar .file-search .input-group .form-control {
	background-color: #fff;
	border-radius: 50px;
	padding-left: 36px;
}

.file-wrap .file-sidebar .file-search .input-group .form-control:focus {
	border-color: #ccc;
	box-shadow: none;
}

.file-wrap .file-sidebar .file-search .input-group .input-group-prepend {
	align-items: center;
	bottom: 0;
	color: #666;
	display: flex;
	left: 15px;
	pointer-events: none;
	position: absolute;
	top: 0;
	z-index: 4;
}

.file-wrap .file-scroll {
	max-height: calc(100vh - 259px);
	min-height: 497px;
	overflow-y: auto;
}

.file-wrap .file-sidebar .file-pro-list {
	background-color: #fff;
	padding: 15px;
}

.file-side-close {
	display: none;
	background-color: #eee;
	border: 1px solid #e3e3e3;
	border-radius: 24px;
	color: #bbb;
	font-size: 14px;
	line-height: 24px;
	min-height: 26px;
	text-align: center;
	width: 26px;
	position: absolute;
	right: 15px;
}

.file-wrap .file-content {
	flex: 0 0 100%;
	max-width: 100%;
}

.file-wrap .file-content .file-header {
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e0e3e4;
	display: flex;
	height: 72px;
	justify-content: space-between;
	padding: 0 15px;
}

.file-wrap .file-content .file-body {
	background-color: #f5f5f6;
}

.dropdown-file {
	position: absolute;
	top: 10px;
	right: 10px;
}

.dropdown-file .dropdown-link {
	color: #777;
	width: 24px;
	height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.dropdown-file .dropdown-link:hover,
.dropdown-file .dropdown-link:focus {
	color: #1b2e4b;
}

.dropdown-file .dropdown-item {
	font-size: 12px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
}

.card-file {
	padding: 10px;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.card-file:hover,
.card-file:focus {
	border-color: #ccc;
}

.card-file .card-body {
	padding: 15px 0 0;
}

.card-file .card-footer {
	font-size: 11px;
	color: #a9a9a9;
	padding: 10px 0 0;
	border-top-width: 0;
	background-color: transparent;
}

.card-file h6 {
	margin-bottom: 0;
	font-size: 0.875rem;
}

.card-file h6 a {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	width: 100%;
	color: #333;
}

.card-file span {
	font-size: 12px;
	color: #888;
}

.card-file-thumb {
	align-items: center;
	background-color: #f5f5f5;
	color: #777;
	display: flex;
	font-size: 48px;
	height: 120px;
	justify-content: center;
}

.file-wrap .file-content .file-body .file-content-inner {
	padding: 15px;
	width: 100%;
}

.file-wrap.file-sidebar-toggle .file-sidebar {
	left: -331px;
}

.file-wrap.file-sidebar-toggle .file-cont-wrap {
	margin-left: 0;
}

.file-wrap .file-cont-wrap {
	display: flex;
	flex-wrap: wrap;
	margin-left: 300px;
	transition: 0.4s ease-in-out;
}

.file-wrap .file-cont-wrap .file-cont-inner {
	flex: 0 0 100%;
	max-width: 100%;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header {
	align-items: center;
	background: #fff;
	border-bottom: 1px solid #e0e3e4;
	display: flex;
	height: 72px;
	justify-content: space-between;
	padding: 0 15px;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header>span {
	color: #333;
	font-size: 20px;
	font-weight: 600;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-sidebar-toggle {
	color: #333;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options {
	display: flex;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>a,
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>span {
	color: #333;
	display: inline-block;
	font-size: 20px;
	margin-left: 25px;
}

.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>a:first-child,
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options>span:first-child {
	margin-left: 0;
}

.file-wrap .file-content .file-search {
	background-color: #f5f5f6;
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 15px;
	width: 100%;
}

.file-wrap .file-content .file-search .input-group {
	width: 100%;
}

.file-wrap .file-content .file-search .input-group .form-control {
	background-color: #fff;
	border-radius: 50px;
	padding-left: 36px;
}

.file-wrap .file-content .file-search .input-group .form-control:focus {
	border-color: #ccc;
	box-shadow: none;
}

.file-wrap .file-content .file-search .input-group .input-group-prepend {
	align-items: center;
	bottom: 0;
	color: #666;
	display: flex;
	left: 15px;
	pointer-events: none;
	position: absolute;
	top: 0;
	z-index: 4;
}

.btn-file {
	display: inline-block;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
}

.btn-file input {
	cursor: pointer;
	direction: ltr;
	filter: alpha(opacity=0);
	font-size: 23px;
	height: 100%;
	margin: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.file-menu {
	display: inline-block;
	margin: 0 0 10px;
	padding: 0;
	width: 100%;
}

.file-menu li {
	display: inline-block;
	width: 100%;
}

.file-menu li+li {
	margin-top: 2px;
}

.file-menu li a {
	color: #333;
	display: inline-block;
	padding: 10px 15px;
	width: 100%;
	text-transform: capitalize;
	-webkit-transition: 0.3s ease;
	-moz-transition: 0.3s ease;
	transition: 0.3s ease;
}

.file-menu li a i {
	font-size: 16px;
	padding-right: 10px;
	color: #878787;
}

.file-menu li a:hover,
.file-menu li.active a,
.file-menu li a:focus {
	background: rgba(33, 33, 33, 0.05);
}

.show-more {
	text-align: center;
}

.show-more a {
	position: relative;
	padding: 0 20px 0 15px;
}

.show-more a:before {
	content: '\f107';
	top: 50%;
	right: 0;
	position: absolute;
	font-size: 18px;
	line-height: inherit;
	font-family: 'FontAwesome';
	transform: translateY(-50%);
}


/*new styles*/

.all-actions a {
	padding: 6px;
	border-radius: 50px;
}

.label-icon:hover {
	background: none !important;
}

.right .tab-pane .card-body {
	padding: 10px;
}

.cd-breadcrumb.triangle .active:after {
	content: '\2713';
	display: inline-block;
	color: #fff;
	position: absolute;
	right: 30px;
	left: unset;
	top: 10px;
}

.slimScrollDiv .card {
	margin: 0;
	border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
	color: #16181b;
	text-decoration: none;
	background-color: #f6f6f6;
}

.checkBox {
	width: 3%;
}

.right .dataTables_info,
.right .pagination,
.right .dataTables_length {
	display: none;
}

.right .table .dropdown .dropdown-menu {
	height: auto;
	min-height: auto;
}

.right .table.custom-table>tbody>tr>td,
.right .table.custom-table>thead>tr>th {
	padding: 12px 8px;
}

.graphs .col-md-6 {
	margin-bottom: 30px;
}

table .badge {
	position: relative;
	top: 6px;
}

.container-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 20px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.container-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.container-checkbox .checkmark {
	position: absolute;
	top: 0px;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #ccc;
}

.container-checkbox:hover input~.checkmark {
	background-color: #ccc;
}

.container-checkbox input:checked~.checkmark {
	background-color: #0d6efd;
}

.container-checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.container-checkbox input:checked~.checkmark:after {
	display: block;
}


/* Style the checkmark/indicator */

.container-checkbox .checkmark:after {
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}


/* The container */

.container-radio {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


/* Hide the browser's default radio button */

.container-radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}


/* Create a custom radio button */

.container-radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.container-radio:hover input~.checkmark {
	background-color: #ccc;
}


/* When the radio button is checked, add a blue background */

.container-radio input:checked~.checkmark {
	background-color: #2196F3;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.container-radio .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}


/* Show the indicator (dot/circle) when checked */

.container-radio input:checked~.checkmark:after {
	display: block;
}


/* Style the indicator (dot/circle) */

.container-radio .checkmark:after {
	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.table.custom-table>tbody>tr>td,
.table.custom-table>thead>tr>th {
	padding: 16px 14px;
}


/*kanban view*/

#sortableKanbanBoards .panel-heading .closed:after {
	content: none !important;
}

.kanban-views .kanban-label h2 {
	color: #000 !important;
	position: relative;
	cursor: pointer;
}

.kanban-views .panel-body {
	max-height: 550px !important;
}

#sortableKanbanBoards .panel-heading {
	display: flex;
	border-radius: 2px;
	line-height: 15px;
}

.flat a.won,
.flat a.won:after {
	background: #0bb325;
	content: "";
}

/* sid */
.flat .planing.won,
.flat .planing.won:after {
	background: #d3d3d3;
	content: "";
}


#sortableKanbanBoards .panel-heading h4,
#sortableKanbanBoards .panel-heading h4:after {
	background: #0dcaf0;
	content: "";
}

.pipeline-project .planing.won,
.pipeline-project .planing.won:after {
	background: #d3d3d3;
	content: "";
}
.Prjt-pipilines a span{
	margin-top: 13px !important;
}
#sortableKanbanBoards .panel-heading h4:first-child {
	padding: 20px 45px;
	border-radius: 2px 0 0 2px;
}

.kanban-label {
	position: relative;
}

/*.kanban-centered {
	border-right: 1px solid #ccc;
}
*/
.kanban-label .nav-profile {
	position: absolute;
	top: 9px;
	right: -5px;
}

.kanban-label .dropdown .dropdown-toggle:after {
	content: none;
}

#sortableKanbanBoards .panel-heading h4 {
	width: -webkit-fill-available;
	text-decoration: none;
	outline: none;
	display: block;
	float: left;
	font-size: 12px;
	color: #fff;
	/* min-width: 285px;
  max-width: 50px;*/
	width: 100%;
	min-width: 100%;
	position: relative;
}

#sortableKanbanBoards .panel-heading h4:after {
	content: '';
	position: absolute;
	top: 2px;
	right: 0;
	width: 50px;
	height: 50px;
	-webkit-transform: scale(.707) rotate(45deg);
	-ms-transform: scale(.707) rotate(45deg);
	-moz-transform: scale(.707) rotate(45deg);
	transform: scale(.707) rotate(45deg);
	z-index: 1;
	/* background: #666; */
	/* background: linear-gradient(135deg,#666,#333); */
	-webkit-box-shadow: 2px -2px 0 1px rgba(255, 255, 255, 1), 3px -3px 0 2px rgba(255, 255, 255, .1);
	box-shadow: 2px -2px 0 1px rgba(255, 255, 255, 1), 3px -3px 0 2px rgba(255, 255, 255, .1);
	border-radius: 0 5px 0 50px;
}

.kanban-centered {
	position: relative;
	margin-bottom: 30px;
}

.kanban-centered:before,
.kanban-centered:after {
	content: " ";
	display: table;
}

.kanban-centered:after {
	clear: both;
}

.kanban-centered:before,
.kanban-centered:after {
	content: " ";
	display: table;
}

.kanban-centered:after {
	clear: both;
}

.kanban-centered:before {
	content: '';
	position: absolute;
	display: block;
	width: 2px;
	/*background: #f5f5f6;*/
	top: 20px;
	bottom: 20px;
	/*margin-left: 18px;*/
}

.kanban-centered .kanban-entry {
	position: relative;
	/*width: 50%;
        float: right;*/
	margin: 10px 8px;
	clear: both;
	border-radius: 4px;
	-webkit-box-shadow: 1px 1px 2px 0px rgba(50, 50, 50, 0.5);
	-moz-box-shadow: 1px 1px 2px 0px rgba(50, 50, 50, 0.5);
	box-shadow: 1px 1px 2px 0px rgba(50, 50, 50, 0.5);
}

.kanban-centered .kanban-entry:before,
.kanban-centered .kanban-entry:after {
	content: " ";
	display: table;
}

.kanban-centered .kanban-entry:after {
	clear: both;
}

.kanban-centered .kanban-entry:before,
.kanban-centered .kanban-entry:after {
	content: " ";
	display: table;
}

.kanban-centered .kanban-entry:after {
	clear: both;
}

.kanban-centered .kanban-entry.begin {
	margin-bottom: 0;
}

.kanban-centered .kanban-entry.left-aligned {
	float: left;
}

.kanban-centered .kanban-entry.left-aligned .kanban-entry-inner {
	margin-left: 0;
	margin-right: -18px;
}

.kanban-centered .kanban-entry.left-aligned .kanban-entry-inner .kanban-time {
	left: auto;
	right: -100px;
	text-align: left;
}

.kanban-centered .kanban-entry.left-aligned .kanban-entry-inner .kanban-icon {
	float: right;
}

.kanban-centered .kanban-entry.left-aligned .kanban-entry-inner .kanban-label {
	margin-left: 0;
	margin-right: 70px;
}

.kanban-centered .kanban-entry.left-aligned .kanban-entry-inner .kanban-label:after {
	left: auto;
	right: 0;
	margin-left: 0;
	margin-right: -9px;
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.kanban-centered .kanban-entry .kanban-entry-inner {
	position: relative;
	/*margin-left: -24px;*/
}

.kanban-centered .kanban-entry .kanban-entry-inner:before,
.kanban-centered .kanban-entry .kanban-entry-inner:after {
	content: " ";
	display: table;
}

.kanban-centered .kanban-entry .kanban-entry-inner:after {
	clear: both;
}

.kanban-centered .kanban-entry .kanban-entry-inner:before,
.kanban-centered .kanban-entry .kanban-entry-inner:after {
	content: " ";
	display: table;
}

.kanban-centered .kanban-entry .kanban-entry-inner:after {
	clear: both;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-time {
	position: absolute;
	left: -100px;
	text-align: right;
	padding: 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-time>span {
	display: block;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-time>span:first-child {
	font-size: 15px;
	font-weight: bold;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-time>span:last-child {
	font-size: 12px;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-icon {
	background: #fff;
	color: #737881;
	display: block;
	width: 25px;
	height: 25px;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	text-align: center;
	-moz-box-shadow: 0 0 0 4px #f5f5f6;
	-webkit-box-shadow: 0 0 0 4px #f5f5f6;
	box-shadow: 0 0 0 4px #f5f5f6;
	float: left;
	margin-top: 6px;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-icon.bg-primary {
	background-color: #303641;
	color: #fff;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-icon.bg-secondary {
	background-color: #ee4749;
	color: #fff;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-icon.bg-success {
	background-color: #00a651;
	color: #fff;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-icon.bg-info {
	background-color: #21a9e1;
	color: #fff;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-icon.bg-warning {
	background-color: #fad839;
	color: #fff;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-icon.bg-danger {
	background-color: #cc2424;
	color: #fff;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-label {
	position: relative;
	padding: 16px;
	box-shadow: 0px 0px 20px 2px #f1f1f1;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-label h2,
.kanban-centered .kanban-entry .kanban-entry-inner .kanban-label p {
	color: #737881;
	font-family: "Noto Sans", sans-serif;
	font-size: 12px;
	margin: 0;
	line-height: 1.428571429;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-label p+p {
	margin-top: 15px;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-label h2 {
	font-size: 16px;
	margin-bottom: 10px;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-label h2 a {
	color: #4a8cf7;
	font-weight: 500;
}

.kanban-centered .kanban-entry .kanban-entry-inner .kanban-label h2 span {
	-webkit-opacity: .6;
	-moz-opacity: .6;
	opacity: .6;
	-ms-filter: alpha(opacity=60);
	filter: alpha(opacity=60);
}

.kanban-col {
	width: 25%;
	margin-right: 20px;
	float: left;
}

.kanban-label {
	cursor: pointer;
}

.kanban-label .nav-profile,
.kanban-label .nav-profile a:hover {
	background: none;
}

table td {
	font-size: 13px;
}

.report-img {
	width: 30px;
}

.advanced-report {
	padding-top: 6px;
}

.mini-sidebar .xs-hidden {
	display: none;
}

.nav-profile,
.nav-profile a:hover {
	background: linear-gradient(to right, #0dcaf0, #0d6efd);
}

.nav-profile {
	margin-bottom: 10px;
}

.nav-profile img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
}

.nav-profile span {
	color: #fff;
}

.page-title .page-title-icon {
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 4px;
	text-align: center;
	-webkit-box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
	box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
}

.page-title .page-title-icon i {
	font-size: .9375rem;
	line-height: 36px;
}

.crms-title .breadcrumb {
	padding-top: 5px;
}

table .progress {
	height: 7px;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	border-radius: 25px;
	position: absolute;
	z-index: -1;
}

::-webkit-scrollbar-track {
	background: #fff;
}

::-webkit-scrollbar-thumb {
	background: #ccc;
	position: absolute;
	z-index: -1;
}


/* Handle */

table .fa-star {
	color: #ffc107;
}

table th {
	color: #8c8989;
	font-size: 13px;
}


/*badge styles*/

table .badge {
	border-radius: .125rem;
	font-size: 11px;
	font-weight: initial;
	line-height: 1;
	padding: .375rem .5625rem;
	font-family: 'Inter', sans-serif;
}

.badge-gradient-danger {
	background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #ffffff;
}

.bg-gradient-success {
	background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
	background: linear-gradient(to right, #84d9d2, #07cdae);
}



.bg-gradient-info {
	background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf)) !important;
	background: linear-gradient(to right, #90caf9, #047edf 99%);
}

.badge-gradient-info {
	background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf)) !important;
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #ffffff;
}

.bg-gradient-warning {
	background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500)) !important;
	background: linear-gradient(to right, #f6e384, #ffd500);
}

.badge-gradient-warning {
	background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500)) !important;
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #ffffff;
}


.badge-gradient-primary {
	background: -webkit-gradient(linear, left top, right top, from(#0dcaf0), to(#0d6efd)) !important;
	background: linear-gradient(to right, #b3eaf5, #69a2f8);
	color: #ffffff;
}

.badge-gradient-success {
	background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #ffffff;
}

/* invoices */
.badge-gradient-due {
	background: -webkit-gradient(linear, left top, right top, from(#33D1FF), to(#33D1FF)) !important;
	background: linear-gradient(to right, #33D1FF, #33D1FF);
	color: #ffffff;
}
.badge-gradient-paid {
	background: -webkit-gradient(linear, left top, right top, from(#33FF58), to(#33FF58)) !important;
	background: linear-gradient(to right, #33FF58, #33FF58);
	color: #ffffff;
}
.badge-gradient-overdue {
	background: -webkit-gradient(linear, left top, right top, from(#FE2D7A), to(#FE2D7A)) !important;
	background: linear-gradient(to right, #FE2D7A, #FE2D7A);
	color: #ffffff;
}
.badge-gradient-canceled {
	background: -webkit-gradient(linear, left top, right top, from(#FC7EF8), to(#FC7EF8)) !important;
	background: linear-gradient(to right, #FC7EF8, #FC7EF8);
	color: #ffffff;
}
.badge-gradient-notyetdue {
	background: -webkit-gradient(linear, left top, right top, from(#FFD433), to(#07cdae)) !important;
	background: linear-gradient(to right, #FFD433, #07cdae);
	color: #ffffff;
}
.badge-gradient-archived {
	background: -webkit-gradient(linear, left top, right top, from(#0C72FD), to(#07cdae)) !important;
	background: linear-gradient(to right, #0C72FD, #07cdae);
	color: #ffffff;
}






/* black */

.badge-gradient-info-black {
	background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf)) !important;
	background: linear-gradient(to right, #90caf9, #047edf 99%);
	color: #000000;
}


.badge-gradient-warning-black {
	background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500)) !important;
	background: linear-gradient(to right, #f6e384, #ffd500);
	color: #000000;
}


.badge-gradient-primary-black {
	background: -webkit-gradient(linear, left top, right top, from(#0dcaf0), to(#0d6efd)) !important;
	background: linear-gradient(to right, #b3eaf5, #69a2f8);
	color: #000000;
}

.badge-gradient-success-black {
	background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
	background: linear-gradient(to right, #84d9d2, #07cdae);
	color: #000000;
}

.badge-gradient-danger-black {
	background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
	background: linear-gradient(to right, #ffbf96, #fe7096);
	color: #000000;
}


/*btn styles*/

.btn-gradient-primary {
	background: linear-gradient(to right, #0dcaf0, #0d6efd);
	font-size: 14px;
	padding: 10px 30px;
	color: #fff;
	border: 0;
	border-radius: 50px;
}

form .btn-secondary {
	font-size: 14px;
	padding: 10px 30px;
	color: #fff;
}

form .btn-gradient-primary {
	font-size: 14px;
	padding: 10px 40px;
	color: #fff;
}

.rounded {
	border-radius: 50px;
}


/* btn style ends*/

.add-lists .dropdown-toggle::after {
	content: none;
}

.fa-th,
.nav-profile-text i {
	color: #0d6efd;
}


/*right side modal styles*/

.modal.left .modal-dialog,
.modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 800px;
	height: 100%;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
	height: 100%;
	/*overflow-y: auto;*/
}


/*.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}*/


/*Right*/

.modal.right.fade .modal-dialog {
	right: 0;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
	right: 0;
}

.right .modal-dialog {
	max-width: 800px;
}

.right form h4 {
	padding: 10px 10px;
	background: #f3f1f1;
}

.User-name {
	font-size: 14px;
	color: #9e9b9b;
	position: absolute;
	left: 18%;
	top: 0px;
}

.md-close {
	display: none;
}

.right .close {
	position: absolute;
	right: 12px !important;
	width: auto;
	height: auto;
	background: none;
	color: #000;
	left: unset;
	top: 18px;
}


/*tabs style*/

.crms-tasks {
	font-size: 1rem;
	width: 100%;
	margin: 0 auto;
	border-radius: 5px;
}

.accordion-header,
.accordion-body {
	background: white;
}

.accordion-header {
	padding: 15px 15px;
	background: #fff;
	color: #000;
	cursor: pointer;
	font-size: 14px;
	letter-spacing: .1em;
	transition: all .3s;
}

.crms-tasks .badge-secondary {
	position: relative;
	right: 7%;
	top: 5px;
}

.accordion-header:after {
	font-family: 'FontAwesome';
	content: "\f078";
	float: right;
	color: #a7a1a1;
}

.active .accordion-header:after {
	/* symbol for "collapsed" panels */
	content: "\f078";
	color: #a7a1a1;
}

.tasks__item {
	border-bottom: 1px solid #ccc;
}

.tasks__item .tasks__item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.accordion-header:hover {
	background: #eaeaea;
	position: relative;
	z-index: 5;
}

.accordion-body {
	background: #fcfcfc;
	color: #353535;
	display: none;
}

.accordion-body__contents {
	padding: 1em 0em;
	font-size: .85em;
}

.tasks__item.active:last-child .accordion-header {
	border-radius: 0;
}

.accordion:first-child>.tasks__item>.accordion-header {
	border-bottom: 1px solid transparent;
}

.tasks__item>.accordion-header:after {
	/*content: "\f078";*/
	font-size: 1.2em;
	float: right;
	position: relative;
	top: -2px;
	transition: .3s all;
	transform: rotate(0deg);
}

.tasks__item.active>.accordion-header:after {
	transform: rotate(-180deg);
}

.tasks__item.active .accordion-header {
	background: #eaeaea;
}

.crms-tasks .active {
	background: #fff;
}

.tasks__item .tasks__item .accordion-header {
	background: #fff;
	color: #353535;
}

.card.card-img-holder .card-img-absolute {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 1;
}

.task-related .card-body {
	padding: 10px;
}

.due-dates span {
	color: #a9a7a7;
}
.due-dates .card-body{
	padding-top: 10px;
	padding-bottom: 10px;
}
.due-dates {
	margin: 0;
}

.user-image {
	width: 100%;
}

.company_img {
	width: 10%;
	margin-right: 10px;
}

.recently-viewed {
	margin-top: 10px;
	display: block;
}

.person-circle {
	border-radius: 100%;
	padding: 4px;
	border-radius: 50%;
	color: rgba(255, 255, 255, .87);
	font-size: 14px;
	height: 30px;
	text-align: center;
	width: 30px;
	display: inline-block;
	margin-right: 10px;
}

.person-circle-a {
	background: #ef5350;
}

.person-circle-b {
	background: #7e57c2;
}

.person-circle-c {
	background: #43a047;
}

.person-circle-d {
	background: #ff5722;
}

.person-circle-e {
	background: #7e57c2;
}

.person-circle-f {
	background: #900c3f;
}

.person-circle-g {
	background: #f54291;
}

.person-circle-h {
	background: #ad6989;
}

.person-circle-i {
	background: #cf7500;
}

.person-circle-j {
	background: #15cda8;
}

.person-circle-k {
	background: #438a5e;
}

.person-circle-l {
	background: #ec823a;
}

.person-circle-m {
	background: #79d70f;
}

.person-circle-n {
	background: #b5076b;
}

.person-circle-o {
	background: #ad6989;
}

/*pipeline tabs style*/


/* Custom nav-tabs */

.flat a.planning,
.flat a.planning:after {
	background: #d3d3d3 !important;
}

.pipeline-tabs {
	border-bottom: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	display: block;
	border-radius: 0 0 0.25em 0.25em;
}

.pipeline-tabs .tab-pane {
	text-align: left;
	padding: 10px;
}

.pipeline-tabs .tab-pane h3 {
	margin: 0;
}

.cd-breadcrumb {
	margin: 0;
	background-color: transparent;
	border-radius: 0.25em 0.25em 0 0;
}

.cd-breadcrumb.nav-tabs {
	border-bottom: none;
}

.cd-breadcrumb.nav-tabs>li.active>a,
.cd-breadcrumb.nav-tabs>li.active>a:hover,
.cd-breadcrumb.nav-tabs>li.active>a:focus {
	color: #fff;
	background-color: #43a047;
	border: 0px solid #43a047;
	cursor: default;
}

.cd-breadcrumb.nav-tabs>li>a {
	margin-right: inherit;
	line-height: inherit;
	height: 48px;
	border: inherit;
	border-radius: inherit;
	border-color: #edeff0;
}

.cd-breadcrumb li {
	display: inline-block;
	float: left;
	margin: 0.5em 0;
}

.cd-breadcrumb li::after {
	/* this is the separator between items */
	display: inline-block;
	content: '\00bb';
	margin: 0 0.6em;
	color: #144677;
}

.cd-breadcrumb li:last-of-type::after {
	/* hide separator after the last item */
	display: none;
}

.cd-breadcrumb li>* {
	display: inline-block;
	font-size: 1.4rem;
	color: #144677;
}

.cd-breadcrumb li.current>* {
	color: #144677;
}

.cd-breadcrumb a:hover {
	color: #144677;
}

.cd-breadcrumb.custom-separator li::after {
	content: '';
	height: 16px;
	width: 16px;
	vertical-align: middle;
}

.cd-breadcrumb li {
	margin: 1.2em 0;
}

.cd-breadcrumb li::after {
	margin: 0 1em;
}

.cd-breadcrumb li>* {
	font-size: 1.6rem;
}

.cd-breadcrumb.triangle li {
	position: relative;
	padding: 0;
	margin: 0;
}

.cd-breadcrumb.triangle li:last-of-type {
	margin-right: 0;
}

.cd-breadcrumb.triangle li .octicon {
	margin-right: 10px;
}

.cd-breadcrumb.triangle li>* {
	position: relative;
	padding: 0.8em 0.8em 0.7em 2.5em;
	color: #333;
	background-color: #edeff0;
	border-color: #edeff0;
}

.cd-breadcrumb.triangle li {
	width: 25%;
}

.cd-breadcrumb.triangle li.active>* {
	/* selected step */
	color: #fff;
	background-color: #144677;
	border-color: #144677;
}

.cd-breadcrumb.triangle li:first-of-type>* {
	padding-left: 1.6em;
	border-radius: 4px 0 0 4px;
	font-size: 12px;
}

.cd-breadcrumb.triangle li a {
	font-size: 12px;
	width: 100%;
	line-height: 30px;
	text-align: center;
	padding: 10px;
}

.cd-breadcrumb.triangle .active,
.cd-breadcrumb.triangle li .active>*::after {
	background: #43a047 !important;
	color: #fff;
}

.cd-breadcrumb.triangle li:last-of-type>* {
	padding-right: 1.6em;
	border-radius: 0 0.25em 0.25em 0;
}

.cd-breadcrumb.triangle a:hover {
	/* steps already visited */
	color: #fff;
	background-color: #43a047;
	border-color: #43a047;
	text-decoration: none;
}

.cd-breadcrumb.triangle li::after,
.cd-breadcrumb.triangle li>*::after {
	content: '';
	position: absolute;
	top: 0;
	left: 100%;
	content: '';
	height: 0;
	width: 0;
	/* border: 24px solid transparent;*/
	border-right-width: 0;
	border-left-width: 20px;
}

.cd-breadcrumb.triangle li::after {
	z-index: 1;
	-webkit-transform: translate(4px, 0);
	-ms-transform: translate(4px, 0);
	-o-transform: translate(4px, 0);
	transform: translate(4px, 0);
	border-left-color: #fff;
	/* reset style */
	margin: 0;
}

.cd-breadcrumb.triangle li>*::after {
	/* this is the colored triangle after each element */
	z-index: 2;
	border-left-color: inherit;
}

.cd-breadcrumb.triangle li:last-of-type::after,
.cd-breadcrumb.triangle li:last-of-type>*::after {
	/* hide the triangle after the last step */
	display: none;
}


/*pipeline styles*/

.modal-body .pipeline-small a {
	max-width: 17%;
	height: 40px;
	text-align: center;
}

.pipeline-small {
	display: flex;
	overflow: hidden;
	border-radius: 2px;
	line-height: 15px;
}

.flat a.won,
.flat a.won:after {
	background: #0bb325;
	content: "";
}

.pipeline-project a.won,
.pipeline-project a.won:after {
	background: #007bff;
	content: "";
}

.pipeline-project .planing.won,
.pipeline-project .planing.won:after {
	background: #d3d3d3;
	content: "";
}

.pipeline-small a:first-child {
	padding-left: 6px;
	border-radius: 2px 0 0 2px;
}

.pipeline-small a {
	width: -webkit-fill-available;
	text-decoration: none;
	outline: none;
	display: block;
	float: left;
	font-size: 12px;
	color: #fff;
	min-width: 30px;
	max-width: 50px;
	background: #666;
	background: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
	background: linear-gradient(#666, #333);
	position: relative;
}

.pipeline-small a:after {
	content: '';
	position: absolute;
	top: -1px;
	right: -4px;
	width: 15px;
	height: 18px;
	-webkit-transform: scale(.707) rotate(45deg);
	-ms-transform: scale(.707) rotate(45deg);
	-moz-transform: scale(.707) rotate(45deg);
	-transform: scale(.707) rotate(45deg);
	z-index: 1;
	background: #666;
	background: linear-gradient(135deg, #666, #333);
	-webkit-box-shadow: 2px -2px 0 1px rgba(255, 255, 255, 1), 3px -3px 0 2px rgba(255, 255, 255, .1);
	box-shadow: 2px -2px 0 1px rgba(255, 255, 255, 1), 3px -3px 0 2px rgba(255, 255, 255, .1);
	border-radius: 0 5px 0 50px;
}

.page-header .dropdown-menu {
	transform: translate3d(-75px, 38px, 0px);
	z-index: 999999;
}

.modal-body .pipeline-small a {
	max-width: 17%;
	height: 40px;
	text-align: center;
	flex: 0 0 17%;
}

.due-dates p {
	font-size: 12px;
	margin-bottom: 0;
}

.won {
	cursor: pointer;
}

.project-pipeline .pipeline-small a {
	max-width: 25%;
	height: 40px;
	text-align: center;
	cursor: pointer;
}

.modal-body .pipeline-small i {
	position: absolute;
	top: 20%;
	left: 45%;
}

#reports .nav-link {
	border: 0 none;
	padding: 10px;
}

.modal-body .pipeline-small a:after {
	width: 40px;
	height: 40px;
}

.padding {
	padding: 10px;
}

.pipeline-small .padding:after {
	display: none;
}

.pipeline-small a span {
	font-size: 9px;
	margin-top: 7px;
	display: inline-block;
}

.pipeline-project .planing.won,
.pipeline-project .planing.won:after {
	background: #d3d3d3;
	content: "";
}

.pipeline-project a.won,
.pipeline-project a.won:after {
	background: #007bff;
	content: "";
}

.project-pipeline .pipeline-small a {
	max-width: 25%;
	height: 40px;
	text-align: center;
	cursor: pointer;
	flex: 0 0 25%;
}

.cancel-button {
	font-size: 14px;
	padding: 9px 26px;
}

.all-reports .nav-tabs .nav-link.active,
.all-reports .nav-tabs .nav-link:hover {
	background: #f6f6f6;
}


/*theme settings*/

.theme-title {
	color: #949090;
	font-size: 14px;
	margin: 0;
	padding-bottom: 10px;
}

.black-mode {
	background: #23272b;
}

.darkness {
	border: 1px solid #ccc;
	padding: 5px 12px;
	color: #fff;
	border-radius: 50px;
	border: 0;
	cursor: pointer;
}

.reset {
	background: #7d7d7d !important;
}

.sidebar-contact {
	position: fixed;
	top: 0%;
	right: -350px;
	/*transform: translateY(-50%);*/
	width: 350px;
	height: auto;
	/*padding:40px;*/
	background: #fff;
	box-sizing: border-box;
	transition: 0.5s;
	z-index: 9999;
}

.sidebar-contact .modal-body {
	overflow-y: auto;
}

.sidebar-contact p {
	font-size: 12px;
	color: #847d7d;
}

.apexcharts-zoom-icon,
.apexcharts-reset-icon,
.apexcharts-menu-icon,
.apexcharts-title-text {
	display: none;
}

.sidebar-contact h4 {
	background: #efefef;
	padding: 1rem;
}

.scroll {
	height: 100vh;
}

.right .modal-header .col-md-5 {
	display: none;
}

.scroll ul {
	border: 1px solid #eaeaea;
	margin-bottom: 10px;
}

.crms-title {
	margin: 0;
	padding: 10px;
}

.scroll h5 {
	font-size: 16px;
	margin: 0;
}

.scroll hr {
	margin: 0;
}

.scroll p {
	color: #949090;
	font-size: 14px;
	margin: 0;
	padding-bottom: 10px;
}

.scroll .list-group-item {
	padding: 8px;
}

.settings.right .modal-dialog {
	width: 400px;
}

.settings .modal-content {
	border-radius: 0;
}

.settings .close {
	position: absolute;
	right: 20px !important;
	width: auto;
	height: auto;
	background: none;
	color: #000;
	left: unset;
	top: 16px;
}

.sidebar-contact.active {
	right: 0;
}

.sidebar-icon-only #mail-label,
.sidebar-icon-only .contacts {
	display: none;
}

.sidebar-icon-only .sidebar .nav .nav-item .nav-link:hover {
	padding-left: 20px;
}

.mail-menu .active {
	background: #0d6efd;
}

.mail-menu .active,
.mail-menu .active i {
	color: #fff !important;
}

.mini-sidebar .nav-profile img {
	position: relative;
	top: 0;
	left: -5px;
}

.mobile-view {
	display: none;
}

.modal {
	z-index: 99999;
}

.right .btn-primary {
	padding: 10px 30px;
}

.right .btn-secondary {
	padding: 8px 25px;
}

#compose-mail .btn-primary {
	padding: .375rem .75rem;
}

.datatables th {
	width: 20%;
}

.chartContainer {
	height: 370px;
	width: 100%;
}

.settings .modal-title {
	padding-left: 5px;
}

.modal-body {
	overflow-y: auto;
}

.right .table {
	margin-bottom: 0;
}

.accordion-body__contents {
	padding: 5px 0;
}

.right table.dataTable {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

#compose-mail .modal-body,
#compose-mail .card-body {
	padding-bottom: 0 !important;
}

/*-----------------
 Roles & Permissions
-----------------------*/

.roles-menu {
	margin-top: 20px;
}
.roles-menu > ul {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    list-style: none;
    margin: 0;
    padding: 0;
}
.roles-menu > ul > li {
	display: block;
	position: relative;
}
.roles-menu > ul > li a {
	border-left: 3px solid transparent;
    color: #333;
    display: block;
    padding: 10px 15px;
    position: relative;
}
.roles-menu > ul > li.active a {
	border-color: #0d6efd;
	color: #0d6efd;
}
.roles-menu > ul > li a:hover {
    background-color: #eee;
	border-color: #0d6efd;
}
.role-action {
	float: right;
	display: none;
}
.roles-menu > ul > li a:hover .role-action {
	display: block;
}
.slide-nav .sidebar {
	margin-left: 0;
}
/*-----------------
  64. Responsive
-----------------------*/

@media only screen and (max-width: 280px) {
	.user-menu {
		display: none;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		width: 92%;
	}
	.sidebar {
		box-shadow: none;
	}
	.header-left img {
		width: 40%;
	}
}

@media screen and (max-width: 767px) {
	.accordion-body .table {
		margin-bottom: 0;
	}
	#comp_tabs .nav-tabs .nav-link {
		padding: 2px 6px;
	}
	.comp-section {
		padding-top: 0;
		padding-bottom: 0;
	}
	.accordion-body__contents .btn-secondary {
		width: 100% !important;
	}
	.nav-link {
		display: block;
		padding: .2rem .5rem;
	}
	.crms-title {
		padding-top: 0;
		padding-bottom: 0;
	}
	.user-menu {
		display: none;
	}
	.mobile-user-menu .fa-ellipsis-v {
		font-size: 16px;
	}
	.page-header i {
		font-size: 14px;
	}
	.user-menu.nav>li>a .badge {
		top: 9px;
		font-size: 10px;
	}
	.nav-profile img {
		top: 0;
	}
	.sidebar {
		box-shadow: none;
	}
	.dropdown a,
	div.dataTables_wrapper div.dataTables_length label,
	.sidebar-menu li a,
	table td,
	table th,
	div.dataTables_wrapper div.dataTables_info,
	div.dataTables_wrapper div.dataTables_paginate ul.pagination,
	.accordion-header,
	.sidebar-menu ul ul a {
		font-size: 12px;
	}
	.btn-gradient-primary {
		font-size: 10px;
	}
	.User-name {
		left: 61px;
	}
	#pipeline-task-details .row {
		margin: 0;
	}
	.right form h4 {
		margin: 10px 0;
	}
	#leads-details .row {
		margin: 0;
	}
	.scroll h5 {
		font-size: 14px;
	}
	.mail-view-title {
		font-size: 16px;
	}
	body {
		font-size: 0.75rem;
	}
	.card {
		margin-bottom: 10px;
	}
	.list-group .col-md-6,
	.list-group .col-md-7,
	.list-group .col-md-5,
	.Default-font .col-md-6 {
		padding: 0 15px !important;
	}
	.table-responsive {
		position: relative;
		z-index: 0;
	}
	#reports .nav-link {
		padding: 8px;
	}
	.cd-breadcrumb.triangle li {
		width: 100%;
		margin-bottom: 3px;
	}
	.crms-tasks .badge-secondary {
		right: 20%;
	}
	.nav-tabs .nav-link {
		padding: .2rem 0rem;
	}
	.nav-tabs.nav-justified {
		border: 0 none;
		border-bottom: 0;
	}
	.rating-star,
	.lock,
	.modal-header ul,
	.crms-title .text-end,
	.due-dates,
	.toggle-close {
		display: none;
	}
	.header-left img {
		width: 30%;
	}
	.personal-info li {
		font-size: 12px;
	}
	.personal-info li .title {
		width: 35%;
	}
	.sender-img {
		float: none;
	}
	.attachments li {
		width: 100%;
	}
	.sender-name {
		padding-top: 14px;
	}
	/*.right .account .modal-title{
    position: relative;
    top: 13px;
    left: 3px;
  }*/
	.company_img {
		width: 14%;
	}
	.settings .modal-title {
		position: initial;
	}
	.right .modal-header .row {
		margin: 0;
	}
	.sidebar {
		box-shadow: none;
	}
	.web-view {
		display: none;
	}
	.faq-card .card .card-header h4>a {
		font-size: 14px;
	}
	.email-header p {
		font-size: 12px;
	}
	.email-header .btn {
		font-size: 12px;
	}
	.modal-body .btn {
		border-radius: 50px;
		font-size: 10px;
	}
	.Reports {
		padding: 0;
		margin-top: 20px;
	}
	.cancel-button {
		padding: 6px 12px !important;
		font-size: 11px;
	}
	.advanced-report,
	h4 {
		font-size: 13px;
	}
	div.dataTables_wrapper div.dataTables_paginate ul.pagination {
		margin: 2px 0;
		white-space: nowrap;
		justify-content: center;
		padding-left: 0;
		padding-right: 25px;
	}
	.card-title {
		font-size: 14px;
	}
	.sidebar form {
		margin: 10px 10px 10px 10px;
		position: relative;
	}
	.sidebar form .btn {
		position: absolute;
		top: 3px;
		right: 0px;
	}
	.mobile-view {
		display: block !important;
	}
	.sidebar-contact {
		width: 320px;
		right: -320px;
	}
	.kanban-centered .kanban-entry {
		margin: 10px 0;
	}
	.sidebar-contact .toggle {
		right: 320px;
	}
	.crms-title .breadcrumb {
		padding-top: 18px;
	}
	.crms-title .text-end {
		padding: 0;
	}
	.graphs .mb-0 {
		margin-bottom: 30px !important;
	}
	.graphs .card {
		margin-bottom: 0;
	}
	.page-title .page-title-icon {
		width: 25px;
		height: 25px;
	}
	.page-title .page-title-icon i {
		line-height: 26px;
	}
	.btn-gradient-primary {
		padding: 8px 9px;
	}
	.modal-title,
	.right form h4 {
		font-size: 14px;
	}
	.col-form-label {
		font-size: 12px;
	}
	.list-inline-item:not(:last-child) {
		margin-right: 0;
	}
	#sortableKanbanBoards .panel-heading h4:first-child {
		padding: 20px 10px;
	}
	.kanban-col {
		width: 100%;
	}
	#sortableKanbanBoards .panel-heading h4:after {
		content: none;
	}
	#sortableKanbanBoards .panel-heading h4 {
		min-width: 100%;
	}
	.h-100 {
		height: auto !important;
	}
	.modal-body .pipeline-small {
		display: none;
	}
	.recently-viewed {
		padding-left: 0px;
	}
	.form-control,
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		font-size: 12px;
	}
	.right .close {
		position: absolute;
		right: 12px !important;
		width: auto;
		height: auto;
		background: none;
		color: #000;
		left: unset;
		top: 15px;
		z-index: 999999;
	}
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		width: 92%;
	}
	.md-close {
		display: none;
	}
	.xs-close {
		display: block;
	}
}

@media only screen and (min-width: 991px) {
	#toggle_btn {
		color: #999;
		float: left;
		font-size: 26px;
		line-height: 60px;
		padding: 0 10px;
	}
	.mini-sidebar .header-left .logo img {
		height: auto;
		max-height: 23px;
		width: auto;
		position: relative;
		top: 17px;
	}
	.mini-sidebar .header .header-left {
		padding: 0 5px;
		width: 60px;
	}
	.mini-sidebar .sidebar {
		width: 60px;
	}
	.mini-sidebar.expand-menu .sidebar {
		width: 230px;
	}
	.mini-sidebar .menu-title {
		visibility: hidden;
		white-space: nowrap;
	}
	.mini-sidebar.expand-menu .menu-title {
		visibility: visible;
	}
	.mini-sidebar .menu-title a {
		visibility: hidden;
	}
	.mini-sidebar.expand-menu .menu-title a {
		visibility: visible;
	}
	.mini-sidebar .sidebar .noti-dot:before {
		display: none;
	}
	.mini-sidebar .sidebar .noti-dot:after {
		display: none;
	}
	.mini-sidebar.expand-menu .sidebar .noti-dot:before {
		display: block;
	}
	.mini-sidebar.expand-menu .sidebar .noti-dot:after {
		display: block;
	}
	.modal-open.mini-sidebar .sidebar {
		z-index: 1001;
	}
	.mini-sidebar .sidebar .sidebar-menu ul>li>a span {
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
	}
	.mini-sidebar .sidebar .sidebar-menu ul>li>a span.chat-avatar-sm {
		display: inline-block;
		opacity: 1;
	}
	.mini-sidebar .sidebar .sidebar-menu ul>li>a span.status {
		display: inline-block;
		opacity: 1;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu ul>li>a span {
		display: inline;
		opacity: 1;
	}
	.mini-sidebar .sidebar .sidebar-menu>ul>li>a i {
		font-size: 20px;
		width: 30px;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li>a i {
		/*font-size: 24px;*/
		width: 20px;
	}
	.mini-sidebar .page-wrapper {
		margin-left: 60px;
	}
}

@media only screen and (min-width: 768px) {
	.modal-md {
		width: 600px;
	}
	.nav-tabs.nav-justified.nav-tabs-top {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs.nav-justified.nav-tabs-top>li>a,
	.nav-tabs.nav-justified.nav-tabs-top>li>a:hover,
	.nav-tabs.nav-justified.nav-tabs-top>li>a:focus {
		border-width: 2px 0 0 0;
	}
	.nav-tabs.nav-tabs-top>li {
		margin-bottom: 0;
	}
	.nav-tabs.nav-tabs-top>li>a,
	.nav-tabs.nav-tabs-top>li>a:hover,
	.nav-tabs.nav-tabs-top>li>a:focus {
		border-width: 2px 0 0 0;
	}
	.nav-tabs.nav-tabs-top>li.open>a,
	.nav-tabs.nav-tabs-top>li>a:hover,
	.nav-tabs.nav-tabs-top>li>a:focus {
		border-top-color: #ddd;
	}
	.nav-tabs.nav-tabs-top>li+li>a {
		margin-left: 1px;
	}
	.nav-tabs.nav-tabs-top>li>a.active,
	.nav-tabs.nav-tabs-top>li>a.active:hover,
	.nav-tabs.nav-tabs-top>li>a.active:focus {
		border-top-color: #0d6efd;
	}
	.nav-tabs.nav-tabs-bottom>li {
		margin-bottom: -1px;
	}
	.nav-tabs.nav-tabs-bottom>li>a.active,
	.nav-tabs.nav-tabs-bottom>li>a.active:hover,
	.nav-tabs.nav-tabs-bottom>li>a.active:focus {
		border-bottom-width: 2px;
		border-color: transparent;
		border-bottom-color: #0d6efd;
		background-color: transparent;
		transition: none 0s ease 0s;
		-moz-transition: none 0s ease 0s;
		-o-transition: none 0s ease 0s;
		-ms-transition: none 0s ease 0s;
		-webkit-transition: none 0s ease 0s;
	}
	.nav-tabs.nav-tabs-solid {
		background-color: #fafafa;
		border: 0;
	}
	.nav-tabs.nav-tabs-solid>li {
		margin-bottom: 0;
	}
	.nav-tabs.nav-tabs-solid>li>a {
		border-color: transparent;
	}
	.nav-tabs.nav-tabs-solid>li>a:hover,
	.nav-tabs.nav-tabs-solid>li>a:focus {
		background-color: #f5f5f5;
	}
	.nav-tabs.nav-tabs-solid>.open:not(.active)>a {
		background-color: #f5f5f5;
		border-color: transparent;
	}
	.nav-tabs-justified.nav-tabs-top {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs-justified.nav-tabs-top>li>a,
	.nav-tabs-justified.nav-tabs-top>li>a:hover,
	.nav-tabs-justified.nav-tabs-top>li>a:focus {
		border-width: 2px 0 0 0;
	}
}

@media only screen and (max-width: 1199.98px) {
	.page-title-box {
		display: none;
	}
	.file-wrap .file-sidebar {
		left: -331px;
	}
	.file-wrap .file-cont-wrap {
		margin-left: 0;
	}
	.file-wrap.file-sidebar-toggle .file-sidebar {
		left: 0;
	}
	.file-wrap.file-sidebar-toggle .file-cont-wrap {
		margin-left: 0;
	}
	.file-side-close {
		display: inline-block;
	}
}

@media only screen and (max-width: 991.98px) {
	.profile-rightbar {
		display: inline-block !important;
	}
	.profile-info-left {
		border-right: none;
		border-bottom: 2px dashed #ccc;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}
	.task-right-sidebar .task-chat-view {
		display: block;
		position: fixed;
		right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		margin-right: 0;
	}
	.task-chat-view {
		display: none;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		right: 0;
		transform: translateX(0px);
		z-index: 1041;
		width: 300px;
		position: fixed;
		transition: all 0.4s ease 0s;
		margin-right: -300px;
		display: table-cell;
		top: 0;
		padding-bottom: 60px;
		margin-top: 60px;
	}
	.chat-profile-view {
		display: none;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		right: 0;
		transform: translateX(0px);
		z-index: 1041;
		width: 300px;
		position: fixed;
		transition: all 0.4s ease 0s;
		margin-right: -300px;
		display: table-cell;
		top: 0;
		padding-bottom: 60px;
		margin-top: 60px;
	}
	.message-view.task-view {
		width: 100%;
	}
	.float-start.ticket-view-details {
		width: 90%;
	}
	.chat-main-row {
		left: 0;
	}
	.header .header-left {
		position: absolute;
		width: 100%;
	}
	.mobile_btn {
		display: block;
	}
	.page-title-box {
		display: none;
	}
	.sidebar {
		margin-left: -225px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
	}
	a.mobile_btn {
		color: #333;
		font-size: 24px;
		height: 60px;
		left: 0;
		line-height: 60px;
		padding: 0 20px;
		position: absolute;
		top: 0;
		width: 60px;
		z-index: 10;
	}
	#toggle_btn {
		display: none;
	}
	.page-wrapper {
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.responsive-search {
		display: inline-block;
	}
	.top-nav-search form {
		display: none;
	}
	.header .has-arrow .dropdown-toggle>span:nth-child(2) {
		display: none;
	}
	.header .has-arrow .dropdown-toggle::after {
		display: none;
	}
}

@media only screen and (max-width: 767.98px) {
	.user-menu.nav>li>a {
		padding: 0 10px;
	}
	.top-nav-search form {
		width: 210px;
	}
	.profile-info-left {
		border-right: none;
		border-bottom: 2px dashed #ccc;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}
	.navbar-nav .open .dropdown-menu {
		float: left;
		position: absolute;
	}
	.notifications {
		right: -48px;
	}
	.notifications::before,
	.notifications::after {
		right: 60px;
	}
	.header {
		z-index: 1040;
	}
	.task-wrapper #task-list li .task-container .task-label {
		padding-right: 50px;
		word-break: unset;
	}
	.task-btn-right {
		position: absolute;
		right: 8px;
	}
	.task-wrapper #task-list li .task-container {
		padding: 8px 10px;
	}
	.task-action-btn.task-check {
		width: 36px;
	}
	.roles-menu {
		margin-bottom: 20px;
	}
	.contacts-list {
		padding: 0 70px 0 15px;
	}
	.left-action {
		text-align: center;
		margin-bottom: 15px;
	}
	.right-action {
		text-align: center;
	}
	.top-action-left .float-start {
		float: none !important;
	}
	.top-action-left .btn-group {
		margin-bottom: 15px;
	}
	.top-action-right {
		text-align: center;
	}
	.top-action-right a.btn.btn-white {
		margin-bottom: 15px;
	}
	.mail-sent-time {
		float: left;
		margin-top: 10px;
		width: 100%;
	}
	.nav-tabs.nav-justified>li>a.active,
	.nav-tabs.nav-justified>li>a.active:hover,
	.nav-tabs.nav-justified>li>a.active:focus {
		border-color: transparent transparent transparent #0d6efd;
		border-left-width: 2px;
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
	}
	.nav-tabs .nav-item {
		margin-bottom: 0;
	}
	.nav-tabs>li>a {
		border-width: 2px;
		border-left-color: transparent;
	}
	.nav-tabs .nav-link {
		border-width: 2px;
	}
	.nav-tabs>li>a:hover,
	.nav-tabs>li>a:focus {
		background-color: #fafafa;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active,
	.nav-tabs>li>a.active,
	.nav-tabs>li>a.active:hover,
	.nav-tabs>li>a.active:focus {
		background-color: #f5f5f5;
		border-color: transparent transparent transparent #0d6efd;
		border-left-width: 2px;
	}
	.nav-tabs>li.open:not(.active)>a,
	.nav-tabs>li.open:not(.active)>a:hover,
	.nav-tabs>li.open:not(.active)>a:focus {
		background-color: #fafafa;
	}
	.nav-tabs.nav-tabs-solid {
		padding: 5px;
	}
	.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
		border-radius: 5px;
	}
	.nav-tabs.nav-tabs-solid>li>a {
		border-left-width: 0!important;
	}
	.nav-tabs-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs-justified>li>a.active,
	.nav-tabs-justified>li>a.active:hover,
	.nav-tabs-justified>li>a.active:focus {
		border-width: 0 0 0 2px;
		border-left-color: #0d6efd;
	}
	.my-video ul li {
		width: 80px;
	}
	.voice-call-avatar .call-avatar {
		width: 120px;
		height: 120px;
	}
	.product-info {
		margin: 20px 0 30px;
	}
	.profile-bg {
		height: 280px;
	}
	.flag-nav>a>span {
		display: none;
	}
	.equal-height-cards>div>div.card {
		height: unset;
	}
}

@media only screen and (max-width: 575.98px) {
	.crms-title .col {
		padding: 0;
	}
	.crms-title .breadcrumb {
		font-size: 8px;
	}
	.contact-cat {
		padding-right: 70px;
	}
	.flag-nav,
	.main-drop {
		display: none;
	}
	.user-menu {
		right: 45px;
		top: 4px;
	}
	.mobile-user-menu {
		display: block;
	}
	.search-box {
		display: none;
	}
	.filter-row {
		margin-bottom: 15px;
	}
	.page-wrapper>.content {
		padding: 15px;
	}
	.profile-view .profile-basic {
		margin-left: 0;
		padding-right: 0;
	}
	.profile-img-wrap {
		position: relative;
		margin: 0 auto;
	}
	.profile-info-left {
		text-align: center;
	}
	.profile-basic {
		margin-left: 0;
		margin-top: 15px;
	}
	.page-title {
		font-size: 16px;
		padding: 10px 0;
	}
	.fc-toolbar .fc-right {
		display: inline-block;
		float: none;
		margin: 10px auto 0;
		width: 200px;
		clear: both;
	}
	.fc-toolbar .fc-left {
		float: none;
		margin: 0 auto;
		width: 200px;
	}
	.upload-text {
		font-size: 18px;
	}
	.call-duration {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		position: inherit;
	}
	.end-call {
		margin-top: 10px;
		position: inherit;
	}
	.chat-panel .chats {
		padding: 15px 0;
	}
	.chat-right .chat-content {
		max-width: 90%;
	}
	.chat-right .chat-body {
		padding-left: 0;
		padding-right: 0;
	}
	.chat-left .chat-content {
		max-width: 90%;
	}
	.chat-left .chat-body {
		padding-left: 0;
		padding-right: 0;
	}
	.chat-avatar .avatar {
		height: 20px;
		line-height: 20px;
		width: 20px;
	}
	.account-box {
		width: 100%;
		margin-bottom: 20px;
	}
	.float-start.ticket-view-details {
		width: 80%;
	}
	.custom-menu>li>a {
		margin-left: 10px;
	}
	.contact-alphapets {
		top: 113px;
	}
	.account-page .main-wrapper {
		flex-direction: column;
	}
	.otp-input {
		font-size: 18px;
		height: 50px;
		margin-right: 10px;
		width: 52px;
	}
	.account-wrapper {
		padding: 15px;
	}
	.leave-right {
		flex: 0 0 auto;
	}
	.pagination-lg .page-link {
		font-size: 1.2rem;
		padding: 0.5rem 0.85rem;
	}
	.call-box .call-avatar {
		height: 100px;
		max-width: 100px;
		min-width: 100px;
	}
	.call-box .call-wrapper .call-items .call-item {
		padding: 10px;
	}
	.call-box .call-wrapper .call-items .call-item.call-end {
		margin: 30px 5px 0;
		padding: 10px;
	}
	.submit-section .submit-btn {
		margin: 0 0 10px !important;
	}
	.leave-row {
		display: block;
	}
	.leave-box .subtitle {
		display: none;
	}
	.leave-right {
		margin-top: 20px;
	}
	.leave-inline-form {
		display: block;
	}
	.page-header .breadcrumb {
		display: none;
	}
	.add-btn {
		font-size: 14px;
		min-width: inherit;
	}
}




.example-chip-list {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    box-shadow: unset;
    min-height: 44px;
  }
  .mat-chip.mat-standard-chip {
    background-color: #9a55ff !important;
    color: #fff;
    margin: 0px 5px 0px 0px;
  }
  .mat-standard-chip {
    border-radius: 0.25rem;
  }
  .mat-icon {
    color: #fff;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 6px 6px 0 !important;
  }





table .badge {
    border-radius: 6px;
    display: inline-block;
    font-size: 14px;
    min-width: 105px;
    padding: 8px 20px;
    font-weight: 500;
    text-align: center;
}
.bg-primary-light {
    background-color: rgba(17, 148, 247, 0.12) !important;
    color: #2196f3 !important;
}
.bg-success-light {
    background-color: rgba(15, 183, 107, 0.12) !important;
    color: #26af48 !important;
}
.bg-danger-light {
    background-color: rgb(255 218 218 / 49%) !important;
    color: #FF0000 !important;
}


